define("idol/initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    ['component', 'controller', 'model', 'route', 'view'].forEach(function (type) {
      application.inject(type, 'intl', 'service:intl');
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});