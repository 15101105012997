define("idol/components/validated-inputs/label-with-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="display-flex justify-between">
    <div class="display-flex">
      <label for="{{@inputId}}">
        {{@label}}{{if @required " *"}}
      </label>
  
      {{yield}}
    </div>
  
    <div class="label-with-action-action">
  
      {{#unless @actionHidden}}
        <button class="lc-btn-link lc-btn-small generate-button" type="button" disabled={{or @actionDisabled @onAction.isRunning}} {{on "click" (perform @onAction)}}>
          {{#if @onAction.isRunning}}
            {{@actionPendingLabel}}
          {{else}}
            {{@actionLabel}}
          {{/if}}
        </button>
      {{/unless}}
  
      {{#if @actionHint}}
        <EmberTooltip @side={{or @actionHintSide "auto"}} @popperContainer={{or @actionHintPopperContainer false}}>
          {{@actionHint}}
        </EmberTooltip>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "SqMqcMnp",
    "block": "[[[10,0],[14,0,\"display-flex justify-between\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[29,[[30,1]]]],[12],[1,\"\\n      \"],[1,[30,2]],[1,[52,[30,3],\" *\"]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,12,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"label-with-action-action\"],[12],[1,\"\\n\\n\"],[41,[51,[30,4]],[[[1,\"      \"],[11,\"button\"],[24,0,\"lc-btn-link lc-btn-small generate-button\"],[16,\"disabled\",[28,[37,3],[[30,5],[30,6,[\"isRunning\"]]],null]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,6]],null]],null],[12],[1,\"\\n\"],[41,[30,6,[\"isRunning\"]],[[[1,\"          \"],[1,[30,7]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,8]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,9],[[[1,\"      \"],[8,[39,6],null,[[\"@side\",\"@popperContainer\"],[[28,[37,3],[[30,10],\"auto\"],null],[28,[37,3],[[30,11],false],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,9]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@inputId\",\"@label\",\"@required\",\"@actionHidden\",\"@actionDisabled\",\"@onAction\",\"@actionPendingLabel\",\"@actionLabel\",\"@actionHint\",\"@actionHintSide\",\"@actionHintPopperContainer\",\"&default\"],false,[\"if\",\"yield\",\"unless\",\"or\",\"on\",\"perform\",\"ember-tooltip\"]]",
    "moduleName": "idol/components/validated-inputs/label-with-action.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});