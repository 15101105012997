define("idol/templates/i/catalog/products/show/distribution/claims/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5TGsObS",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/products/show/distribution/claims/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});