define("idol/templates/components/i-grid-collection-item-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jvFXeozq",
    "block": "[[[11,3],[24,6,\"#\"],[24,0,\"grid-collection-item-new-inner flex-column center\"],[4,[38,0],[[30,0],[28,[37,1],[[30,0,[\"newAction\"]]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"icon-create\"],[12],[13],[1,\"\\n  \"],[1,[30,0,[\"placeholder\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"optional\"]]",
    "moduleName": "idol/templates/components/i-grid-collection-item-new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});