define("idol/templates/components/i-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/JBCbJX",
    "block": "[[[10,\"img\"],[15,\"src\",[30,0,[\"_src\"]]],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"play-overlay center cover-play\"],[12],[1,\"\\n\"],[41,[30,0,[\"isPlaying\"]],[[[1,\"    \"],[1,[28,[35,1],[\"pause\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],[\"play\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"svg-jar\"]]",
    "moduleName": "idol/templates/components/i-cover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});