define("idol/templates/components/i-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cJ6ce7zN",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,0,[\"dotsCount\"]]],null]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"loader-circle\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"_\"],false,[\"each\",\"-track-array\",\"repeat\"]]",
    "moduleName": "idol/templates/components/i-loader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});