define("idol/templates/down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uO+c/c4F",
    "block": "[[[10,0],[14,0,\"down-message center flex-column\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"down\"],[[\"class\"],[\"down-image\"]]]],[1,\"\\n\"],[41,[30,0,[\"model\",\"isMaintenance\"]],[[[1,\"    \"],[10,\"h1\"],[12],[1,\"The service is currently under maintenance\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"We should get back shortly\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h1\"],[12],[1,\"The service is temporarily unavailable\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"We are working on fixing the issue, please wait until the problem is solved\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,2],[12],[1,\"See our \"],[10,3],[14,6,\"https://status.idol.io\"],[14,\"target\",\"_blank\"],[12],[1,\"status page\"],[13],[1,\" for further information\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"svg-jar\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/down.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});