define("idol/validations/product", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {// name: [
    //   validatePresence(true)
    // ],
    // productionYear: [
    //   validatePresence(true)
    // ],
    // productionLine: [
    //   validatePresence(true)
    // ],
    // copyrightLine: [
    //   validatePresence(true)
    // ],
    // upcCode: [
    //   validatePresence(true)
    // ],
    // defaultOffer: {
    //   releaseDate: validatePresence(true)
    // }
  };
  _exports.default = _default;
});