define("idol/templates/components/i-submission-selected-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6+qUTSpe",
    "block": "[[[8,[39,0],[[24,0,\"center\"]],[[\"@name\",\"@image\"],[[30,0,[\"model\",\"fullName\"]],[30,0,[\"model\",\"avatarUrl\"]]]],null],[1,\"\\n\"],[1,[30,0,[\"model\",\"fullName\"]]],[1,\"\\n\"]],[],false,[\"i-avatar\"]]",
    "moduleName": "idol/templates/components/i-submission-selected-user.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});