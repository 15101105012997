define("idol/templates/invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "flPt/QPK",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"invitation.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],[[24,0,\"form form-signin center flex-column\"]],[[\"@model\",\"@on-submit\"],[[30,0],[28,[37,3],[[30,0,[\"signup\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"logo align-center\"],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-inner form-reset-password-inner\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"invitation-message\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"invitation.message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@label\",\"@name\",\"@placeholder\"],[false,\"login\",[28,[37,1],[\"invitation.login.placeholder\"],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@type\",\"@label\",\"@name\",\"@placeholder\"],[\"password\",false,\"password\",[28,[37,1],[\"invitation.password.placeholder\"],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@type\",\"@label\",\"@name\",\"@placeholder\"],[\"password\",false,\"passwordConfirmation\",[28,[37,1],[\"invitation.passwordConfirmation.placeholder\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"errors\"]],[[[1,\"      \"],[10,0],[14,0,\"form-error\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"errors\"]]],null]],null],null,[[[1,\"          \"],[1,[30,2]],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n      \"],[8,[30,1,[\"submit\"]],[[16,\"disabled\",[30,1,[\"loading\"]]]],[[\"@class\",\"@label\"],[\"lc-btn lc-btn-primary signin\",[52,[30,1,[\"loading\"]],[28,[37,1],[\"saving\"],null],[28,[37,1],[\"signup\"],null]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"f\",\"error\"],false,[\"page-title\",\"t\",\"validated-form\",\"perform\",\"if\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/invitation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});