define("idol/templates/components/i-right-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J832RIta",
    "block": "[[[1,[30,0,[\"model\",\"action\"]]],[1,\"\\n\"],[1,[30,0,[\"model\",\"computedPlatforms\"]]],[1,\"\\n\"],[1,[30,0,[\"model\",\"computedTerritories\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "idol/templates/components/i-right-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});