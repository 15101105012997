define("idol/transforms/fragment-array", ["exports", "ember-data-model-fragments/transforms/fragment-array"], function (_exports, _fragmentArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragmentArray.default.reopen({
    deserialize: function deserialize(serialized) {
      if (Ember.isNone(serialized)) {
        return this._super([]);
      }

      return this._super(serialized);
    }
  });

  _exports.default = _default;
});