define("idol/components/validated-inputs/validated-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="formfield {{if @isValid "is-valid"}} {{if @isInvalid "is-invalid"}}">
    <div class="display-flex">
      <@labelComponent />
      <@hintComponent />
    </div>
  
    <IDatepicker @selected={{or @value.content @value}} @onSelect={{queue @update @setDirty}} @renderInPlace={{@renderInPlace}} @allowClear={{@allowClear}} @minDate={{@minDate}} @maxDate={{@maxDate}} />
  
    <@errorComponent class="d-block" />
  </div>
  */
  {
    "id": "Fo0x4eJ7",
    "block": "[[[10,0],[15,0,[29,[\"formfield \",[52,[30,1],\"is-valid\"],\" \",[52,[30,2],\"is-invalid\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n    \"],[8,[30,3],null,null,null],[1,\"\\n    \"],[8,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@selected\",\"@onSelect\",\"@renderInPlace\",\"@allowClear\",\"@minDate\",\"@maxDate\"],[[28,[37,2],[[30,5,[\"content\"]],[30,5]],null],[28,[37,3],[[30,6],[30,7]],null],[30,8],[30,9],[30,10],[30,11]]],null],[1,\"\\n\\n  \"],[8,[30,12],[[24,0,\"d-block\"]],null,null],[1,\"\\n\"],[13]],[\"@isValid\",\"@isInvalid\",\"@labelComponent\",\"@hintComponent\",\"@value\",\"@update\",\"@setDirty\",\"@renderInPlace\",\"@allowClear\",\"@minDate\",\"@maxDate\",\"@errorComponent\"],false,[\"if\",\"i-datepicker\",\"or\",\"queue\"]]",
    "moduleName": "idol/components/validated-inputs/validated-datepicker.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});