define("idol/templates/components/i-follow-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yfhxZ/SO",
    "block": "[[[11,\"button\"],[16,0,[29,[\"lc-btn-icon lc-btn-secondary lc-btn-rounded \",[52,[30,0,[\"isFollowingParent\"]],\"is-following-parent\"],\" \",[52,[30,0,[\"isFollowing\"]],\"is-following\"]]]],[4,[38,1],[[30,0],\"toggleFollow\",[30,0,[\"followable\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isFollowing\"]],[[[1,\"    \"],[1,[28,[35,2],[\"unfollow\"],[[\"class\"],[\"display-flex\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[\"follow\"],[[\"class\"],[\"display-flex\"]]]],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@side\"],[[30,0,[\"side\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isFollowingParent\"]],[[[1,\"      \"],[1,[28,[35,4],[\"follow.alreadyFollowingParent\"],[[\"type\"],[[28,[37,5],[[28,[37,4],[[28,[37,6],[\"resources.\",[30,0,[\"computedFollow\",\"following\",\"modelName\"]]],null]],null]],null]]]]],[1,\" \"],[10,\"strong\"],[12],[1,[30,0,[\"computedFollow\",\"following\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isFollowing\"]],[[[1,\"      \"],[1,[28,[35,4],[\"follow.unfollow\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"follow.follow\"],null]],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\\n\"],[13]],[],false,[\"if\",\"action\",\"svg-jar\",\"ember-tooltip\",\"t\",\"lowercase\",\"concat\"]]",
    "moduleName": "idol/templates/components/i-follow-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});