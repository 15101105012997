define("idol/templates/components/i-offers-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3fi7GY4",
    "block": "[[[41,[30,0,[\"model\",\"isDefault\"]],[[[1,\"  \"],[10,\"img\"],[15,\"src\",[30,0,[\"model\",\"product\",\"coverUrl\"]]],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"model\",\"isMultiple\"]],[[[1,\"  \"],[10,0],[15,0,[52,[30,0,[\"model\",\"isMultiple\"]],\"table-column-offers-cover-multiples\"]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[4,[30,0,[\"model\",\"dspUploadIdentifications\"]]],null]],null]],null],null,[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,1,[\"dsp\",\"imageUrl\"]]],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"img\"],[15,\"src\",[30,0,[\"model\",\"dspUploadIdentifications\",\"firstObject\",\"dsp\",\"imageUrl\"]]],[12],[13],[1,\"\\n\"]],[]]]],[]]]],[\"dspUploadIdentification\"],false,[\"if\",\"each\",\"-track-array\",\"take\"]]",
    "moduleName": "idol/templates/components/i-offers-cover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});