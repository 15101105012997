define("idol/templates/i/distribution/deliveries/send-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tdiwc1Mj",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"sendTasks.title\"],null]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"content-header justify-between\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"sendTasks.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"content-header-actions display-flex\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"lc-btn-icon lc-btn-square lc-btn-secondary\"],[4,[38,2],[[30,0],\"requestExtract\"],null],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"download-small\"],null]],[1,\"\\n        \"],[8,[39,4],null,[[\"@side\",\"@enableLazyRendering\"],[\"left\",true]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[\"sendTasks.toolbar.metadata\"],[[\"format\"],[\".csv\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],[[24,0,\"lc-btn lc-btn-primary content-header-action\"]],[[\"@params\"],[[28,[37,6],[\"i.distribution.deliveries.send-task-factory.new\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"sendTasks.toolbar.new\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@items\",\"@linkPath\",\"@translationPath\"],[[28,[37,6],[\"collection\",\"send-task-factories\"],null],\"i.distribution.deliveries.send-tasks\",\"sendTasks.distribution.nav\"]],null],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"page-title\",\"t\",\"action\",\"svg-jar\",\"ember-tooltip\",\"link-to\",\"array\",\"i-subnav\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/distribution/deliveries/send-tasks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});