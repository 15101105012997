define("idol/utils/analytics", ["exports", "accounting/format-number", "accounting/format-money", "idol/utils/currency", "ember-inflector"], function (_exports, _formatNumber, _formatMoney, _currency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatValue = formatValue;

  function formatValue() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var unit = arguments.length > 1 ? arguments[1] : undefined;

    if (unit.get('isCurrency')) {
      return (0, _formatMoney.default)((0, _currency.default)(value));
    }

    return "".concat((0, _formatNumber.default)(value), " ").concat((0, _emberInflector.pluralize)(unit.get('unit')));
  }
});