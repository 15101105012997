define("idol/helpers/analytics/columns-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.analyticsColumnsFor = analyticsColumnsFor;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function analyticsColumnsFor(params, hash) {
    var _params = _slicedToArray(params, 2),
        type = _params[0],
        value = _params[1];

    var tableType = hash.tableType,
        transactionType = hash.transactionType;

    if (value === 'usages') {
      return [{
        name: 'usage',
        valuePath: 'name',
        isFixed: 'left',
        width: 180,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 120
      }, {
        name: 'revenue',
        valuePath: 'revenue',
        width: 160
      }, {
        name: 'premium',
        paymentKey: 'premium',
        valuePath: 'premium.revenue',
        width: 160
      }, {
        name: 'freemium',
        paymentKey: 'freemium',
        valuePath: 'freemium.revenue',
        width: 160
      }, {
        name: 'aLaCarte',
        paymentKey: 'aLaCarte',
        valuePath: 'aLaCarte.revenue',
        width: 160
      }, {
        name: 'trial',
        paymentKey: 'trial',
        valuePath: 'trial.revenue',
        width: 160
      }, {
        name: 'none',
        paymentKey: 'none',
        valuePath: 'none.revenue',
        width: 160
      }];
    }

    if (value === 'ages' && type === 'trend') {
      return [{
        name: 'age',
        valuePath: 'name',
        isFixed: true,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 180,
        textAlign: 'center'
      }];
    }

    if (value === 'retails' && type === 'trend' && tableType !== 'ranking') {
      return [{
        name: 'retail',
        valuePath: 'name',
        isFixed: true,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 180,
        textAlign: 'center'
      }];
    }

    if (value === 'origins') {
      return [{
        name: 'origin',
        valuePath: 'name',
        isFixed: 'left',
        width: 180,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 120
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 160
      }, {
        name: 'user',
        creatorKey: 1,
        valuePath: 'user.quantity',
        width: 160
      }, {
        name: 'automated',
        creatorKey: 3,
        valuePath: 'automated.quantity',
        width: 160
      }, {
        name: 'editorial',
        creatorKey: 2,
        valuePath: 'editorial.quantity',
        width: 160
      }];
    }

    if (value === 'playlists') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'followers',
        valuePath: 'followers',
        width: 180,
        textAlign: 'center',
        type: 'number'
      }, {
        name: 'curator',
        valuePath: 'curator',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'skipRate',
        valuePath: 'secondMetric',
        width: 180,
        textAlign: 'center'
      }];
    }

    if (value === 'tracks' && type === 'trend' && transactionType === 'streaming') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        minWidth: 300,
        textAlign: 'left'
      }, {
        name: 'artist',
        valuePath: 'artist',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 100,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'uniqueUsers',
        valuePath: 'uniqueUsers',
        width: 140,
        textAlign: 'center',
        type: 'number'
      }, {
        name: 'streamPerUsers',
        valuePath: 'streamPerUsers',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'skipRate',
        valuePath: 'secondMetric',
        width: 125,
        textAlign: 'center',
        hintPath: 'analytics.playlists.skipRateHint'
      }];
    }

    if (/products|artists/.test(value) && type === 'trend' && transactionType === 'streaming') {
      var columns = [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        minWidth: 300,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'uniqueUsers',
        valuePath: 'uniqueUsers',
        width: 140,
        textAlign: 'center',
        type: 'number'
      }, {
        name: 'streamPerUsers',
        valuePath: 'streamPerUsers',
        width: 140,
        textAlign: 'center'
      }];

      if (value === 'products') {
        columns.insertAt(2, {
          name: 'artist',
          valuePath: 'artist',
          width: 140,
          textAlign: 'center'
        });
      }

      return columns;
    }

    if (value === 'territories' && type === 'trend' && transactionType === 'fingerprint') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        minWidth: 300,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 140,
        textAlign: 'center',
        hintPath: 'analytics.fragments.territories.quantityHint'
      }];
    }

    if (type === 'trend') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        minWidth: 300,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 140,
        textAlign: 'center'
      }, {
        name: 'quantity',
        valuePath: 'value',
        width: 140,
        textAlign: 'center'
      }];
    }

    if (value === 'ages' && type === 'sale') {
      return [{
        name: 'age',
        valuePath: 'name',
        isFixed: true,
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'revenue',
        valuePath: 'value',
        width: 180,
        textAlign: 'center'
      }];
    }

    if (/products|tracks/.test(value) && type === 'sale') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        textAlign: 'left'
      }, {
        name: 'artist',
        valuePath: 'artist',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'revenue',
        valuePath: 'revenue',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'transactions',
        valuePath: 'transactions',
        width: 180,
        textAlign: 'center'
      }];
    }

    if (type === 'sale') {
      return [{
        name: 'rank',
        valuePath: 'rank',
        isFixed: true,
        width: 40,
        textAlign: 'center'
      }, {
        name: 'name',
        valuePath: 'name',
        textAlign: 'left'
      }, {
        name: 'proportion',
        valuePath: 'percent',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'revenue',
        valuePath: 'revenue',
        width: 180,
        textAlign: 'center'
      }, {
        name: 'transactions',
        valuePath: 'transactions',
        width: 180,
        textAlign: 'center'
      }];
    }

    return [];
  }

  var _default = Ember.Helper.helper(analyticsColumnsFor);

  _exports.default = _default;
});