define("idol/templates/components/i-grid-collection-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B50WBjOz",
    "block": "[[[8,[39,0],[[24,0,\"collection-grid-item-inner\"]],[[\"@route\",\"@model\"],[[30,0,[\"link\"]],[30,0,[\"item\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"collection-grid-item-cover\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"item\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"collection-grid-item-infos\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"collection-grid-item-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"subtitle\"]],[[[1,\"      \"],[10,0],[14,0,\"collection-grid-item-subtitle\"],[12],[1,[28,[35,3],[\"collection.count.product\"],[[\"count\"],[[30,0,[\"subtitle\"]]]]]],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,0,[\"subtitle\"]],0],null],[[[1,\"      \"],[10,0],[14,0,\"collection-grid-item-subtitle\"],[12],[1,[28,[35,3],[\"collection.count.products.zero\"],null]],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"link-to\",\"i-cover\",\"if\",\"t\",\"eq\"]]",
    "moduleName": "idol/templates/components/i-grid-collection-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});