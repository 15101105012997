define("idol/templates/components/i-power-select-submission-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w1+aVgXG",
    "block": "[[[8,[39,0],null,[[\"@triggerClass\",\"@dropdownClass\",\"@options\",\"@allowClear\",\"@searchField\",\"@selectedItemComponent\",\"@selected\",\"@onChange\",\"@placeholderComponent\",\"@renderInPlace\"],[\"select-submission-trigger select-submission-trigger-user\",\"select-submission-dropdown select-submission-dropdown-user\",[30,0,[\"users\"]],true,\"fullName\",[50,\"i-submission-selected-user\",0,null,[[\"model\"],[[30,0,[\"model\",\"assignUser\"]]]]],[30,0,[\"model\",\"assignUser\"]],[28,[37,2],[[30,0],\"updateUser\"],null],\"i-submission-selected-user-placeholder\",true]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"select-submission-user align-center\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"center\"]],[[\"@name\",\"@image\"],[[30,1,[\"fullName\"]],[30,1,[\"avatarUrl\"]]]],null],[1,\"\\n      \"],[1,[30,1,[\"fullName\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"resource\"],false,[\"power-select\",\"component\",\"action\",\"i-avatar\"]]",
    "moduleName": "idol/templates/components/i-power-select-submission-user.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});