define("idol/templates/components/i-grouped-cover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rgIqX/G4",
    "block": "[[[10,0],[14,0,\"table-column-cover\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"covers\",\"length\"]],1],null],[[[1,\"    \"],[10,0],[14,0,\"table-column-cover-multiples\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[4,[30,0,[\"filteredCovers\"]]],null]],null]],null],null,[[[1,\"        \"],[10,\"img\"],[15,\"src\",[30,1]],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"img\"],[15,\"src\",[30,0,[\"covers\",\"firstObject\"]]],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[18,2,null]],[\"cover\",\"&default\"],false,[\"if\",\"gt\",\"each\",\"-track-array\",\"take\",\"yield\"]]",
    "moduleName": "idol/templates/components/i-grouped-cover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});