define("idol/helpers/rolecount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rolecount = rolecount;
  _exports.default = void 0;

  function rolecount(obj) {
    return obj[0].length;
  }

  var _default = Ember.Helper.helper(rolecount);

  _exports.default = _default;
});