define("idol/initializers/register-query-params-service", ["exports", "ember-query-params-service/initializers/register-query-params-service"], function (_exports, _registerQueryParamsService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _registerQueryParamsService.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _registerQueryParamsService.initialize;
    }
  });
});