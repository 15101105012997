define("idol/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S9Vq83bB",
    "block": "[[[10,0],[14,0,\"application-wrapper\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@position\"],[\"top\"]],null],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"application-updater align-center justify-between\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"newVersion\"],null]],[1,\"\\n      \"],[10,0],[14,0,\"application-updater-actions align-center\"],[12],[1,\"\\n        \"],[10,\"button\"],[14,0,\"lc-btn lc-btn-primary\"],[15,\"onclick\",[28,[37,3],[[30,0],[30,3]],null]],[14,4,\"button\"],[12],[1,[28,[35,2],[\"reload\"],null]],[13],[1,\"\\n        \"],[10,3],[14,6,\"#\"],[14,0,\"lc-btn-link lc-btn-link-secondary\"],[15,\"onclick\",[28,[37,3],[[30,0],[30,4]],null]],[12],[1,[28,[35,2],[\"close\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1,2,3,4]]]]],[1,\"\\n\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"productBuilder\",\"isBuildingProduct\"]],[[[1,\"    \"],[10,0],[14,5,\"position: absolute;\"],[12],[1,\"\\n      \"],[8,[39,9],null,[[\"@didEndBuildingProduct\"],[[28,[37,10],[[28,[37,11],[[30,0,[\"productBuilder\",\"isBuildingProduct\"]],false],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"newVersion\",\"oldVersion\",\"reload\",\"close\"],false,[\"notification-container\",\"new-version-notifier\",\"t\",\"action\",\"component\",\"-outlet\",\"i-application\",\"ember-load-remover\",\"if\",\"products/builder\",\"fn\",\"mut\"]]",
    "moduleName": "idol/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});