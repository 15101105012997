define("idol/templates/i/catalog/products/show/artists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vHobP7VH",
    "block": "[[[8,[39,0],null,[[\"@visible\",\"@didCloseModal\"],[true,[28,[37,1],[[30,0],\"didCancel\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[28,[37,2],[\"products.show.artists.title\"],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\"]],[[[1,\"    \"],[10,0],[14,0,\"grid-50\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"        \"],[8,[39,6],null,[[\"@params\",\"@class\"],[[28,[37,7],[\"i.roster.artists.show\",[30,2,[\"id\"]]],null],\"link-default-color products-artists-list-item\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[\"products.show.artists.empty\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[\"m\",\"artist\"],false,[\"i-modal\",\"action\",\"t\",\"if\",\"each\",\"-track-array\",\"link-to\",\"array\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/products/show/artists/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});