define("idol/templates/signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b9p2dzNq",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"signin.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],[[24,0,\"form form-signin center flex-column\"]],[[\"@model\",\"@on-submit\"],[[30,0],[28,[37,3],[[30,0],\"authenticate\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"logo align-center\"],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-inner form-signin-inner\"],[12],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[10,0],[14,0,\"form-error display-flex center\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[28,[37,5],[\"signin.error.\",[28,[37,6],[[30,0,[\"error\"]]],null]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@label\",\"@name\",\"@placeholder\"],[false,\"username\",[28,[37,1],[\"signin.form.placeholder.user\"],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@type\",\"@label\",\"@name\",\"@placeholder\"],[\"password\",false,\"password\",[28,[37,1],[\"signin.form.placeholder.password\"],null]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n      \"],[8,[30,1,[\"submit\"]],[[16,\"disabled\",[30,1,[\"loading\"]]]],[[\"@class\",\"@label\"],[\"lc-btn lc-btn-primary signin\",[52,[30,1,[\"loading\"]],[28,[37,1],[\"signingin\"],null],[28,[37,1],[\"signin.form.signin\"],null]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,7],[[24,0,\"lc-btn-link lc-btn-link-secondary form-signin-link\"]],[[\"@route\"],[\"password.reset\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"signin.form.passwordForgotten\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[\"f\"],false,[\"page-title\",\"t\",\"validated-form\",\"action\",\"if\",\"concat\",\"camelize\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/signin.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});