define("idol/templates/i/changelogs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rgdqntG9",
    "block": "[[[10,0],[14,0,\"content-header no-padding-bottom justify-between\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"changelog.title\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"changelog-items\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"changelog-item display-flex\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"changelog-item-date\"],[12],[1,[28,[35,3],[[30,1,[\"date\"]],\"ll\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"changelog-item-content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"changelog-item-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n          \"],[10,0],[14,0,\"changelog-item-description\"],[12],[2,[30,1,[\"content\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"changelog\"],false,[\"t\",\"each\",\"-track-array\",\"moment-format\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/changelogs/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});