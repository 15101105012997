define("idol/instance-initializers/ember-hifi", ["exports", "idol/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var emberHifi = _environment.default.emberHifi,
        _config$environment = _environment.default.environment,
        environment = _config$environment === void 0 ? 'development' : _config$environment;
    var options = {
      emberHifi: emberHifi,
      environment: environment
    };
    application.register('config:hifi', options, {
      instantiate: false
    });
    application.inject('service:hifi', 'options', 'config:hifi');
  }

  var _default = {
    name: 'ember-hifi',
    initialize: initialize
  };
  _exports.default = _default;
});