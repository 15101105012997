define("idol/templates/password/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OIwBz1Ew",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"password.reset.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],[[24,0,\"form form-signin center flex-column\"]],[[\"@model\",\"@on-submit\"],[[30,0],[28,[37,3],[[30,0,[\"reset\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"logo align-center\"],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-inner form-signin-inner\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"form-reset-password-hint\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"password.reset.hint\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@label\",\"@name\",\"@placeholder\"],[false,\"email\",[28,[37,1],[\"password.reset.placeholder\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"errors\"]],[[[1,\"      \"],[10,0],[14,0,\"form-error\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"errors\"]]],null]],null],null,[[[1,\"          \"],[1,[30,2]],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"message\"]],[[[1,\"      \"],[10,0],[14,0,\"form-message\"],[12],[1,\"\\n        \"],[1,[30,0,[\"message\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n      \"],[8,[30,1,[\"submit\"]],[[16,\"disabled\",[30,1,[\"loading\"]]]],[[\"@class\",\"@label\"],[\"lc-btn lc-btn-primary reset\",[52,[30,1,[\"loading\"]],[28,[37,1],[\"resetting\"],null],[28,[37,1],[\"password.form.reset\"],null]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,7],[[24,0,\"lc-btn-link lc-btn-link-secondary form-signin-link\"]],[[\"@route\"],[\"signin\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"password.reset.passwordRecovered\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null]],[\"f\",\"error\"],false,[\"page-title\",\"t\",\"validated-form\",\"perform\",\"if\",\"each\",\"-track-array\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/password/reset.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});