define("idol/mixins/collection-route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PER_PAGE = 50;

  var _default = Ember.Mixin.create({
    collectionName: null,
    templateName: 'collection',
    infinity: Ember.inject.service(),
    _perPage: DEFAULT_PER_PAGE,
    init: function init() {
      (false && !(this.collectionName) && Ember.assert('collectionName property should be defined', this.collectionName));

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var parentModel = this._super.apply(this, arguments);

      var model = this.infinity.model(this.collectionName, this.infinityParams(parentModel, params));
      return Ember.RSVP.hash({
        model: model,
        parentModel: new Ember.RSVP.Promise(function (resolve) {
          resolve(parentModel);
        })
      });
    },
    setupController: function setupController(controller, _ref) {
      var model = _ref.model,
          parentModel = _ref.parentModel;

      this._super(controller, null);

      controller.set('model', model);
      controller.set('parentModel', parentModel);
      controller.set('collectionName', (0, _emberInflector.pluralize)(this.collectionName));
    },
    renderTemplate: function renderTemplate(controller, model) {
      if (this.templateName) {
        return this.render(this.templateName, {
          controller: controller
        });
      }

      return this._super.apply(this, arguments);
    },
    paramsPathFor: function paramsPathFor(collection) {
      var path = 'catalog';

      if (/artist|label/.test(this.get('collectionName'))) {
        path = 'roster';
      }

      if (/claim/.test(this.get('collectionName'))) {
        path = 'distribution';
      }

      return "i.".concat(path, ".").concat((0, _emberInflector.pluralize)(collection), ".collection");
    },
    infinityParams: function infinityParams(parentModel) {
      var params = this.paramsFor(this.paramsPathFor(this.get('collectionName')));
      var filter = {};
      Object.keys(params).forEach(function (key) {
        var value = params[key];

        if (key === 'statusesParam') {
          key = 'statuses';
        }

        if (!Ember.isEmpty(value) && value !== 'all') {
          filter[Ember.String.dasherize(key)] = value;
        }
      });
      return {
        filter: filter
      };
    }
  });

  _exports.default = _default;
});