define("idol/templates/components/i-search-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCVbI3Nb",
    "block": "[[[41,[30,0,[\"pendingSearch\"]],[[[1,\"  \"],[1,[28,[35,1],[\"search.searching\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"initialItems\",\"length\"]],[[[1,\"    \"],[10,\"ul\"],[14,0,\"search-list-inner\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[14,0,\"search-list-item\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@model\",\"@linkable\"],[[30,1],true]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,0,[\"items\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@infinityModel\",\"@classNames\"],[[30,0,[\"items\"]],\"justify-center column\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],[[24,0,\"loader-red\"]],[[\"@dotsCount\"],[\"3\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[1,[28,[35,1],[\"search.noResults.title\"],null]],[1,\"\\n\"]],[]]]],[]]]],[\"item\"],false,[\"if\",\"t\",\"each\",\"-track-array\",\"i-search-list-item\",\"infinity-loader\",\"i-loader\"]]",
    "moduleName": "idol/templates/components/i-search-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});