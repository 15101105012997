define("idol/mixins/resource-searchable", ["exports", "idol/utils/group-by", "ember-inflector"], function (_exports, _groupBy, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEBOUNCE_MS = 600;

  var _default = Ember.Mixin.create({
    resources: [],
    isFiltering: Ember.computed('selectedResources.[]', function () {
      return this.hasResourceQueryParams();
    }),
    resourcesWithQueryParams: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var queryParamAttributes = this.resourceQueryParamAttributes();

      if (!this.get('resourcesWithQueryParams')) {
        this.set('queryParams', this.get('queryParams').concat(queryParamAttributes));
      }

      this.initSelectedResourcesProperties(queryParamAttributes, 'selectedResources');
      this.get('resources').forEach(function (resource) {
        var queryParamKey = _this.resourceQueryParamAttributeFor(resource);

        _this.initResourceProperties(queryParamKey, resource);
      });
    },
    _performSearch: function _performSearch(query, targets, resolve, reject) {
      var _this2 = this;

      return this.store.query('search', Object.assign({}, {
        query: query,
        targets: targets.join(',')
      }, this.searchResourcesParams())).then(function (resources) {
        var groupedResources = (0, _groupBy.default)(resources, function (resource) {
          return resource.get('modelName');
        });
        var keys = Object.keys(groupedResources); // Don't results if there's only one type seached

        if (targets.length === 1) {
          return resolve(groupedResources[keys.get('firstObject')] || []);
        }

        return resolve(keys.map(function (key, i) {
          return {
            groupName: (0, _emberInflector.pluralize)(_this2.intl.t("resources.".concat(Ember.String.camelize(key))).toString()),
            options: groupedResources[key],
            count: resources.meta['type-record-count'][key]
          };
        }));
      });
    },
    _searchResources: function _searchResources(term) {
      var _this3 = this;

      var query = term;
      var targets = this.searchResourcesTargets(); // When we provide targets as an argument of this action it'll be curried before the term argument

      if (arguments.length === 3) {
        query = arguments[1];
        targets = term;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.debounce(_this3, _this3._performSearch, query, targets, resolve, reject, DEBOUNCE_MS);
      });
    },
    actions: {
      searchResources: function searchResources(term) {
        return this._searchResources.apply(this, arguments);
      },
      changeSelectedResources: function changeSelectedResources(resources) {
        var _this4 = this;

        var groupedResource = (0, _groupBy.default)(resources, function (resource) {
          return (0, _emberInflector.pluralize)(resource.get('modelName'));
        });
        this.get('resources').forEach(function (key) {
          _this4.set(key, groupedResource[Ember.String.dasherize(key)] || []);
        });
        this.set('selectedResources', resources);
      }
    },
    initSelectedResourcesProperties: function initSelectedResourcesProperties(queryParamAttributes, selectedResourcesAttribute) {
      Ember.defineProperty(this, selectedResourcesAttribute, Ember.computed.apply(Ember, _toConsumableArray(queryParamAttributes).concat([{
        get: function get(key) {
          var _this5 = this;

          var ids = this.resourceQueryParamValues();

          if (!this.hasSelectedResources() && this.hasResourceQueryParams()) {
            var result = [];
            Object.keys(ids).forEach(function (resource) {
              var resourceIds = ids[resource];

              if (!Ember.isEmpty(resourceIds)) {
                _this5.store.all(resource.replace('Ids', ''), {
                  filter: {
                    ids: resourceIds
                  },
                  page: {
                    size: 200
                  }
                }).then(function (resources) {
                  result.addObjects(resources);
                });
              }
            });
            this.set("_".concat(selectedResourcesAttribute), result);
          }

          return this.get("_".concat(selectedResourcesAttribute));
        },
        set: function set(key, value) {
          this.set("_".concat(selectedResourcesAttribute), value);
          return value;
        }
      }])));
    },
    initResourceProperties: function initResourceProperties(queryParamKey, resource) {
      var excluded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var privateResourceKey = "_".concat(resource);
      this.set(privateResourceKey, []);
      Ember.defineProperty(this, "".concat(excluded).concat(resource), Ember.computed(privateResourceKey, queryParamKey, {
        get: function get(key) {
          var _resource = this.get(privateResourceKey);

          var resourceIds = this.get(queryParamKey);

          if (!_resource && resourceIds) {
            this.set(privateResourceKey, this.store.query(resource, {
              filter: {
                ids: resourceIds
              }
            }));
          }

          return this.get(privateResourceKey);
        },
        set: function set(key, value) {
          this.set(queryParamKey, value && value.length ? value.mapBy('id').join(',') : undefined);
          this.set(privateResourceKey, value);
          return value;
        }
      }));
    },
    addResource: function addResource(type, id) {
      var _this6 = this;

      // this is absolutely ugly, but fast.
      this.store.findRecord((0, _emberInflector.singularize)(type), id).then(function (resource) {
        var resources = _this6.get(type) || [];
        resources.addObject(resource);

        _this6.set(type, resources);

        var selectedResources = _this6.get('selectedResources') || [];
        selectedResources.addObject(resource);

        _this6.set('selectedResources', selectedResources);
      });
    },
    addResources: function addResources(resources) {
      var _this7 = this;

      var selectedResources = this.get('selectedResources') || [];
      resources = resources.concat(selectedResources);
      var groupedResource = (0, _groupBy.default)(resources, function (resource) {
        return (0, _emberInflector.pluralize)(resource.get('modelName'));
      });
      this.get('resources').forEach(function (key) {
        _this7.set(key, groupedResource[Ember.String.dasherize(key)] || []);
      });
      this.set('selectedResources', resources);
    },
    excludedResourcesTargets: ['distributorProductSubgenre'],
    searchResourcesTargets: function searchResourcesTargets() {
      var _this8 = this;

      return this.get('resources').map(function (target) {
        return (0, _emberInflector.singularize)(target);
      }).filter(function (target) {
        return !_this8.get('excludedResourcesTargets').includes(target);
      });
    },
    searchResourcesParams: function searchResourcesParams() {
      var params = {
        page: {
          size: 5
        },
        additionnal_filters: {}
      };
      var distributorId = this.session.get('currentDistributor.id');

      if (this.session.get('currentUser.isMultipleDistributor')) {
        params.additionnal_filters['distributor_id'] = distributorId;
      }

      return params;
    },
    hasSelectedResources: function hasSelectedResources() {
      return this.get('_selectedResources') !== undefined && !this.get('_selectedResources').length;
    },
    hasResourceQueryParams: function hasResourceQueryParams() {
      var resources = this.get('resources');
      var ids = this.resourceQueryParamValues();
      return !!Object.keys(ids).filter(function (key) {
        return !Ember.isEmpty(ids[key]);
      }).length;
    },
    resourceQueryParamAttributes: function resourceQueryParamAttributes() {
      var _this9 = this;

      return this.get('resources').map(function (resource) {
        return _this9.resourceQueryParamAttributeFor(resource);
      });
    },
    resourceQueryParamValues: function resourceQueryParamValues() {
      return this.getProperties.apply(this, _toConsumableArray(this.resourceQueryParamAttributes()));
    },
    resourceQueryParamAttributeFor: function resourceQueryParamAttributeFor(resource) {
      return "".concat((0, _emberInflector.singularize)(resource), "Ids");
    },
    resetResources: function resetResources() {
      var _this10 = this;

      this.set('selectedResources', []);
      this.get('resources').forEach(function (resource) {
        _this10.set(resource, undefined);
      });
    }
  });

  _exports.default = _default;
});