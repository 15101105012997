define("idol/templates/i/roster/artists/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJI7sSsA",
    "block": "[[[1,[28,[35,0],[[30,0,[\"model\",\"name\"]]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@top\"],[60]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,2],[\"content-header \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]],null]],[12],[1,\"\\n    \"],[10,0],[14,0,\"content-header-name inline-flex align-center\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@path\",\"@message\"],[\"i.roster.artists\",[28,[37,5],[\"backTo\"],null]]],null],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[30,0,[\"model\",\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@items\",\"@linkPath\",\"@translationPath\"],[[30,0,[\"navItems\"]],\"i.roster.artists.show\",\"artists.nav\"]],null],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"se\"],false,[\"page-title\",\"sticky-element\",\"concat\",\"if\",\"i-back-to\",\"t\",\"i-subnav\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/roster/artists/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});