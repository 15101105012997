define("idol/helpers/duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.duration = duration;
  _exports.default = void 0;

  function duration(params
  /*, hash*/
  ) {
    var value = params[0] || 0;
    var result = (0, _moment.default)().startOf('day').seconds(value);

    if (value >= 3600) {
      return result.format('H:mm:ss');
    }

    return result.format('mm:ss');
  }

  var _default = Ember.Helper.helper(duration);

  _exports.default = _default;
});