define("idol/helpers/substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.substring = substring;
  _exports.default = void 0;

  function substring(params, _ref) {
    var start = _ref.start,
        end = _ref.end;
    return (params[0] || "").substring(start, end);
  }

  var _default = Ember.Helper.helper(substring);

  _exports.default = _default;
});