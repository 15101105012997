define("idol/templates/i/catalog/products/show/distribution/rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wnVbfEhX",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"i-rights-reader\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/products/show/distribution/rights.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});