define("idol/templates/components/i-back-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXNe0od1",
    "block": "[[[8,[39,0],[[24,0,\"content-header-backTo display-flex\"]],[[\"@route\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"chevron-big-left\"],null]],[1,\"\\n  \"],[8,[39,2],null,[[\"@side\"],[\"bottom\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@path\"],false,[\"link-to\",\"svg-jar\",\"ember-tooltip\"]]",
    "moduleName": "idol/templates/components/i-back-to.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});