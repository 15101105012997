define("idol/components/validated-inputs/validated-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="formfield {{if @isValid "is-valid"}} {{if @isInvalid "is-invalid"}}">
    {{#if (eq @type "switch")}}
      <div class="display-flex">
        <PaperSwitch @value={{@value}} @onChange={{queue @update @setDirty}} @noink={{true}} @class="tiny form-input-height" @disabled={{or @disabled false}}>
          {{@label}}
        </PaperSwitch>
        <@hintComponent/>
      </div>
    {{else}}
    <div class="display-flex">
      <PaperCheckbox @value={{@value}} @onChange={{queue @update @setDirty}} @disabled={{or @disabled false}}>
        {{@label}}
      </PaperCheckbox>
      <@hintComponent/>
     </div>
    {{/if}}
  
    <@errorComponent />
  </div>
  */
  {
    "id": "RKNQVoZQ",
    "block": "[[[10,0],[15,0,[29,[\"formfield \",[52,[30,1],\"is-valid\"],\" \",[52,[30,2],\"is-invalid\"]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,3],\"switch\"],null],[[[1,\"    \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@value\",\"@onChange\",\"@noink\",\"@class\",\"@disabled\"],[[30,4],[28,[37,3],[[30,5],[30,6]],null],true,\"tiny form-input-height\",[28,[37,4],[[30,7],false],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,8]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,9],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@value\",\"@onChange\",\"@disabled\"],[[30,4],[28,[37,3],[[30,5],[30,6]],null],[28,[37,4],[[30,7],false],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,8]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,9],null,null,null],[1,\"\\n   \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[30,10],null,null,null],[1,\"\\n\"],[13]],[\"@isValid\",\"@isInvalid\",\"@type\",\"@value\",\"@update\",\"@setDirty\",\"@disabled\",\"@label\",\"@hintComponent\",\"@errorComponent\"],false,[\"if\",\"eq\",\"paper-switch\",\"queue\",\"or\",\"paper-checkbox\"]]",
    "moduleName": "idol/components/validated-inputs/validated-checkbox.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});