define("idol/templates/components/i-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mV0mE9Ld",
    "block": "[[[10,0],[14,0,\"toolbar-inner\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "idol/templates/components/i-toolbar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});