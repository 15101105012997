define("idol/utils/post-message", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    handlers: [],
    startHandling: function startHandling() {
      (0, _jquery.default)(window).on('message', this._handleMessage.bind(this));
    },
    stopHandling: function stopHandling() {
      (0, _jquery.default)(window).off('message', this._handleMessage.bind(this));
    },
    receiveMessage: function receiveMessage(type, callback) {
      var handler = this.handlers[type];

      if (!handler) {
        this.handlers[type] = callback;
      }
    },
    _handleMessage: function _handleMessage(event) {
      var data = event.originalEvent.data;
      var handler = this.handlers[data.type];

      if (handler) {
        handler.call(this, data);
      }
    }
  });

  _exports.default = _default;
});