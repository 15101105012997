define("idol/initializers/install-function-modifier-manager", ["exports", "ember-functions-as-modifiers-polyfill/initializers/install-function-modifier-manager"], function (_exports, _installFunctionModifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _installFunctionModifierManager.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _installFunctionModifierManager.initialize;
    }
  });
});