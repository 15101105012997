define("idol/templates/components/chart/chart-sunburst", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sd7+g+i0",
    "block": "[[[10,0],[14,0,\"sunburst-chart\"],[15,1,[29,[\"chart-\",[30,0,[\"type\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "idol/templates/components/chart/chart-sunburst.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});