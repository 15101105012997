define("idol/helpers/numeric-duration", ["exports", "ember-hifi/helpers/numeric-duration"], function (_exports, _numericDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _numericDuration.default;
    }
  });
  Object.defineProperty(_exports, "numericDuration", {
    enumerable: true,
    get: function get() {
      return _numericDuration.numericDuration;
    }
  });
});