define("idol/templates/components/expendable-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cocRwZZP",
    "block": "[[[10,\"img\"],[15,\"src\",[30,0,[\"src\"]]],[14,5,\"display: block;\"],[12],[13],[1,\"\\n\\n\"],[11,0],[24,0,\"item-informations-cover-hover\"],[4,[38,0],[[30,0],[28,[37,1],[\"visible\",[30,0]],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"zoom\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@visible\",\"@modalClass\"],[[30,0,[\"visible\"]],\"expendable-image-modal\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"src\"]]],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"m\"],false,[\"action\",\"toggle-action\",\"svg-jar\",\"i-modal\"]]",
    "moduleName": "idol/templates/components/expendable-img.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});