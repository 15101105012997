define("idol/metrics-adapters/google-tag-manager", ["exports", "ember-metrics/metrics-adapters/google-tag-manager"], function (_exports, _googleTagManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleTagManager.default;
    }
  });
});