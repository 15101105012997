define("idol/templates/i/analytics/trends/demographics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "olhOWpnE",
    "block": "[[[10,0],[14,0,\"inner-sub-navigation-sticky\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@top\"],[160]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"inner-sub-navigation \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"nav-pills\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"inner-sub-navigation-content nav-pills-inner\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"navItems\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[1,\"\\n              \"],[8,[39,4],[[24,0,\"nav-button\"]],[[\"@route\",\"@model\"],[\"i.analytics.trends.demographics.demographic\",[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,5],[[28,[37,6],[\"analytics.demographics.subNav.\",[28,[37,7],[[30,2]],null]],null]],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[\"se\",\"key\"],false,[\"sticky-element\",\"if\",\"each\",\"-track-array\",\"link-to\",\"t\",\"concat\",\"camelize\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/analytics/trends/demographics.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});