define("idol/utils/promise-race", ["exports", "ember-hifi/utils/promise-race"], function (_exports, _promiseRace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _promiseRace.default;
    }
  });
});