define("idol/components/multiselect-checkboxes-dropdown", ["exports", "ember-multiselect-checkboxes-dropdown/components/multiselect-checkboxes-dropdown"], function (_exports, _multiselectCheckboxesDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _multiselectCheckboxesDropdown.default;
    }
  });
});