define("idol/templates/i/distribution/dsp-upload-identifications/dsp-upload-identification/mappings/track-prices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DVWYx+Ht",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"dspUploadIdentifications.mappings.trackPrices.title\"],null]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"dspUploadIdentifications.mappings.trackPrices.title\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"mappings-list price-codes-list\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mappings-list-item price-codes-list-item\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"distributorPriceCodes\",\"length\"]],[[[1,\"          \"],[8,[39,3],[[24,0,\"mappings-list-mapper price-codes-list-mapper\"]],[[\"@mappables\",\"@options\",\"@dspUploadIdentification\",\"@isMappable\",\"@onSelect\"],[[28,[37,4],[\"dspMetadata1\",[30,0,[\"model\",\"distributorPriceCodes\"]]],null],[28,[37,4],[\"name\",[30,0,[\"model\",\"priceCodes\"]]],null],[30,0,[\"model\",\"dspUploadIdentification\"]],[28,[37,5],[[30,0,[\"model\",\"dspUploadIdentification\",\"features\",\"noRateCard\"]]],null],[28,[37,6],[\"map\"],null]]],[[\"mappable\",\"option\"],[[[[1,\"\\n              \"],[1,[30,1,[\"fullName\"]]],[1,\"\\n            \"]],[1]],[[[1,\"\\n              \"],[1,[30,2,[\"fullName\"]]],[1,\"\\n            \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,2],[12],[1,[28,[35,1],[\"dspUploadIdentifications.mappings.empty\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null]],[\"mappable\",\"option\"],false,[\"page-title\",\"t\",\"if\",\"dsp-upload-identification/mapper\",\"sort-by\",\"not\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/distribution/dsp-upload-identifications/dsp-upload-identification/mappings/track-prices.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});