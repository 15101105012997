define("idol/templates/components/modal/modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpSC+Oco",
    "block": "[[[10,0],[14,0,\"align-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-column\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-header-title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-header-subtitle\"],[12],[1,\"\\n      \"],[1,[30,0,[\"subtitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,0],[24,0,\"modal-header-close center\"],[4,[38,1],[[30,0],[30,0,[\"closeModalAction\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"cross-big\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"action\",\"svg-jar\"]]",
    "moduleName": "idol/templates/components/modal/modal-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});