define("idol/templates/components/i-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "up7beDQ7",
    "block": "[[[11,0],[16,0,[29,[[52,[30,1,[\"length\"]],\"tags\",\"no-tags\"],\" \",[52,[30,0,[\"isEditing\"]],\"editing\",\"not-editing\"]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"input-tag\"]],[[\"@tagName\",\"@triggerClass\",\"@placeholder\",\"@searchMessage\",\"@selected\",\"@onChange\",\"@onCreate\",\"@onOpen\",\"@onClose\",\"@search\",\"@registerAPI\",\"@renderInPlace\",\"@showCreateWhen\"],[\"div\",\"input-tag-trigger\",[30,3],[28,[37,2],[\"tags.searchMessage\"],null],[30,1],[30,0,[\"selectTag\"]],[30,0,[\"createTag\"]],[30,0,[\"onOpen\"]],[30,0,[\"onClose\"]],[28,[37,3],[[30,0,[\"search\",\"searchResources\"]],[28,[37,4],[[28,[30,0,[\"resourceFor\"]],[[30,4]],null]],null]],null],[30,0,[\"registerAPI\"]],true,[30,0,[\"showCreateWhen\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,[[30,5]]],[1,\"\\n  \"]],[5]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@to\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"lc-btn-icon lc-btn-secondary lc-btn-rounded\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"startEditing\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,8],[\"tag\"],null]],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@side\"],[\"bottom\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"catalog.products.show.tag\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@selected\",\"&attrs\",\"@placeholder\",\"@target\",\"resource\",\"@tagElement\",\"&default\"],false,[\"if\",\"power-select-multiple-with-create\",\"t\",\"fn\",\"array\",\"yield\",\"ember-wormhole\",\"on\",\"svg-jar\",\"ember-tooltip\"]]",
    "moduleName": "idol/templates/components/i-tag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});