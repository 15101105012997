define("idol/templates/components/skeletons/chart-header-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rVASLoTb",
    "block": "[[[8,[39,0],[[24,\"viewBox\",\"0 0 80 20\"]],[[\"@width\",\"@height\",\"@preserveAspectRatio\",\"@class\"],[80,20,\"xMidYMid slice\",\"chart-header\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"rx\",\"3\"],[14,\"ry\",\"3\"],[14,\"width\",\"80\"],[14,\"height\",\"6\"],[12],[13],[1,\"\\n    \"],[10,\"rect\"],[14,\"x\",\"15\"],[14,\"y\",\"10\"],[14,\"rx\",\"3\"],[14,\"ry\",\"3\"],[14,\"width\",\"50\"],[14,\"height\",\"6\"],[12],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"content-loader\"]]",
    "moduleName": "idol/templates/components/skeletons/chart-header-skeleton.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});