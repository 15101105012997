define("idol/templates/components/skeletons/chart-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4hnkcYYO",
    "block": "[[[8,[39,0],[[24,\"viewBox\",\"0 0 400 100\"]],[[\"@width\",\"@height\",\"@preserveAspectRatio\",\"@class\"],[400,100,\"xMaxYMid meet\",\"chart-header\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"rx\",\"2\"],[14,\"ry\",\"2\"],[14,\"width\",\"400\"],[14,\"height\",\"96\"],[12],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"content-loader\"]]",
    "moduleName": "idol/templates/components/skeletons/chart-skeleton.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});