define("idol/components/ember-truncate/truncation-noop", ["exports", "ember-truncate/components/ember-truncate/truncation-noop"], function (_exports, _truncationNoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _truncationNoop.default;
    }
  });
});