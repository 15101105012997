define("idol/templates/i/catalog/videos/collection/index-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gDstsAN1",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"sections-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"justify-center column infinity-loader\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"loader-red\"]],[[\"@dotsCount\"],[3]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"i-loader\"]]",
    "moduleName": "idol/templates/i/catalog/videos/collection/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});