define("idol/mixins/rightable", ["exports", "@ember-data/model", "ember-custom-actions", "lodash/array"], function (_exports, _model, _emberCustomActions, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    computedRights: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    rights: (0, _model.hasMany)('right'),
    computedDsps: Ember.computed('computedRights', function () {
      var distributor = this.get('session.currentDistributor');
      var computedRights = this.get('computedRights') || [];
      var computedDsps = [];
      computedRights = computedRights.findBy('name', distributor.get('name'));

      if (computedRights) {
        computedRights.rights.splice(-1, 1); // last right element are not distributed dsps we dont want to gather here

        computedDsps = computedRights.rights.map(function (territory) {
          return territory.dsps;
        });
      }

      return (0, _array.flatten)(computedDsps);
    }),
    isLoadingRights: false,
    reloadComputedRights: function reloadComputedRights(right) {
      var _this = this;

      this.set('isLoadingRights', true);
      var body = right ? right.serialize({
        includeId: true
      }) : {};
      return this.previewRights(body).then(function (model) {
        _this.set('isLoadingRights', false);

        return model;
      }).catch(function () {
        _this.set('isLoadingRights', false);
      });
    },
    previewRights: (0, _emberCustomActions.modelAction)('preview_rights', {
      method: 'POST',
      pushToStore: true,
      responseType: 'object'
    })
  });

  _exports.default = _default;
});