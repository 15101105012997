define("idol/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(xs, getter) {
    return xs.reduce(function (rv, x) {
      (rv[getter(x)] = rv[getter(x)] || []).push(x);
      return rv;
    }, {});
  }
});