define("idol/templates/components/i-power-select-submission-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyRR/cwk",
    "block": "[[[8,[39,0],null,[[\"@triggerClass\",\"@searchEnabled\",\"@options\",\"@selected\",\"@onChange\",\"@renderInPlace\"],[[29,[\"select-submission-trigger \",[30,0,[\"submission\",\"status\"]]]],false,[30,0,[\"statuses\"]],[30,0,[\"submission\",\"status\"]],[28,[37,1],[[30,0],\"updateStatus\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,3],[\"submissions.statuses.\",[28,[37,4],[[30,1]],null]],null]],null]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"status\"],false,[\"power-select\",\"action\",\"t\",\"concat\",\"camelize\"]]",
    "moduleName": "idol/templates/components/i-power-select-submission-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});