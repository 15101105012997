define("idol/components/validated-input/-themes/bootstrap/hint", ["exports", "ember-validated-form/components/validated-input/-themes/bootstrap/hint"], function (_exports, _hint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hint.default;
    }
  });
});