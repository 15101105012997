define("idol/templates/components/i-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9E1fU3tz",
    "block": "[[[1,[28,[35,0],[\"informations\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@side\",\"@tooltipClassName\",\"@popperContainer\"],[[30,1],[28,[37,2],[[28,[37,3],[[30,2],\"ember-tooltip lc-tooltip \"],null],[52,[30,3],\" lc-tooltip-interactive \"]],null],[28,[37,3],[[30,4],false],null]]],[[\"default\"],[[[[1,\"\\n  \"],[2,[30,5]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@side\",\"@tooltipClass\",\"@interactive\",\"@popperContainer\",\"@message\"],false,[\"svg-jar\",\"ember-popover\",\"concat\",\"or\",\"if\"]]",
    "moduleName": "idol/templates/components/i-hint.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});