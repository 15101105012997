define("idol/components/i-enhanced-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberPopover @tooltipClass="" @side={{@side}} as |popover|>
    <div class="enhanced-popover">
      {{!-- header --}}
      {{#if (has-block "header")}}
        <div class="enhanced-popover-header">
          <div class="justify-between align-center">
            {{yield to="header"}}
            {{!-- le CTA ne fonctionne pas pour l'instant --}}
            {{!-- <button class="lc-btn-link lc-btn-link-secondary lc-btn-icon display-flex" {{action 'hideHelp' }}>
              {{svg-jar "hide" width="18" height="18"}}
              {{t "tracks.helpPopover.buttonHide"}}
            </button> --}}
          </div>
        </div>
      {{/if}}
      {{!-- body --}}
      {{#if (has-block "body")}}
        <div class="enhanced-popover-body">
          {{yield to="body"}}
        </div>
      {{/if}}
      {{!-- footer --}}
      {{#if (has-block "footer")}}
        <div class="enhanced-popover-footer">
          {{yield to="footer"}}
        </div>
      {{/if}}
    </div>
  </EmberPopover>
  
  {{yield}}
  */
  {
    "id": "T4ryfFko",
    "block": "[[[8,[39,0],null,[[\"@tooltipClass\",\"@side\"],[\"\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"enhanced-popover\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[10,0],[14,0,\"enhanced-popover-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"justify-between align-center\"],[12],[1,\"\\n          \"],[18,3,null],[1,\"\\n\"],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,4]],[[[1,\"      \"],[10,0],[14,0,\"enhanced-popover-body\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,5]],[[[1,\"      \"],[10,0],[14,0,\"enhanced-popover-footer\"],[12],[1,\"\\n        \"],[18,5,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"\\n\\n\"],[18,6,null]],[\"@side\",\"popover\",\"&header\",\"&body\",\"&footer\",\"&default\"],false,[\"ember-popover\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "idol/components/i-enhanced-popover.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});