define("idol/templates/i/analytics/sales/rankings/ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzEgX3NC",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@fragment\",\"@exportEnabled\"],[\"sale\",[36,1],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,[[\"@subtitle\",\"@number\"],[[28,[37,2],[[28,[37,3],[[30,2,[\"numbers\",\"trackQuantity\"]]],[[\"allowEmpty\"],[true]]],\" \",[28,[37,4],[\"analytics.rankings.column.transactions\"],null]],null],[28,[37,5],[[28,[37,6],[[30,2,[\"numbers\",\"amount\"]]],null]],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"chart\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@tableClass\",\"@fillMode\",\"@fillColumnIndex\",\"@onItemClick\"],[\"ember-table-analytics-ranking\",\"nth-column\",1,[28,[37,7],[[30,0],\"addAsFilter\"],null]]],null],[1,\"\\n\"]],[1,2,3]]]]]],[\"analytic\",\"fragmentModel\",\"model\"],false,[\"i-analytics\",\"model\",\"concat\",\"format-number\",\"t\",\"format-money\",\"currency\",\"action\"]]",
    "moduleName": "idol/templates/i/analytics/sales/rankings/ranking.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});