define("idol/templates/products/edit/tracks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zPA0CmW6",
    "block": "[[[8,[39,0],null,[[\"@product\",\"@volumes\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"volumes\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"funnel-footer-button\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"lc-btn lc-btn-secondary\"]],[[\"@route\"],[\"products.edit.index\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"products.button.previous\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"lc-btn lc-btn-primary\"]],[[\"@route\"],[\"products.edit.rights\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"products.button.next\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"products/volumes\",\"link-to\",\"t\"]]",
    "moduleName": "idol/templates/products/edit/tracks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});