define("idol/mixins/duplicate-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      duplicate: function duplicate(product, track) {
        var _this = this;

        if (track) {
          track.clone().then(function (track) {
            _this.get('notifications').success(_this.intl.t('catalog.albums.duplicate.success'));

            _this.transitionToRoute('i.catalog.products.show', track.get('product'));
          }).catch(function () {
            _this.get('notifications').warning(_this.intl.t('catalog.albums.duplicate.failed'));
          });
        } else {
          product.clone().then(function (product) {
            _this.get('notifications').success(_this.intl.t('catalog.albums.duplicate.success'));

            _this.transitionToRoute('i.catalog.products.show', product);
          }).catch(function () {
            _this.get('notifications').warning(_this.intl.t('catalog.albums.duplicate.failed'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});