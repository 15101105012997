define("idol/templates/components/table-with-actions/table-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sqeeofkA",
    "block": "[[[10,0],[14,0,\"table-body\"],[12],[1,\"\\n\"],[41,[30,0,[\"onSelect\",\"isRunning\"]],[[[1,\"    \"],[10,0],[14,0,\"st-table-body-saving justify-center\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"loader-red\"]],[[\"@dotsCount\"],[3]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"i-loader\",\"yield\"]]",
    "moduleName": "idol/templates/components/table-with-actions/table-body.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});