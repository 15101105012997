define("idol/components/button-multiple/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="lc-btn-icon">{{svg-jar "arrow-select-big"}}</button>
  */
  {
    "id": "eKSl+R6H",
    "block": "[[[10,\"button\"],[14,0,\"lc-btn-icon\"],[12],[1,[28,[35,0],[\"arrow-select-big\"],null]],[13]],[],false,[\"svg-jar\"]]",
    "moduleName": "idol/components/button-multiple/trigger.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});