define("idol/templates/i/distribution/dsp-upload-identifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOk/4LAw",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"dspUploadIdentifications.title\"],null]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"page-title\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/distribution/dsp-upload-identifications.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});