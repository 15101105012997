define("idol/templates/components/analytics/analytics-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYG4h3HZ",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"fragmentModel\"]],[[[41,[28,[37,2],[[30,0,[\"fragment\"]],\"territories\"],null],[[[1,\"    \"],[10,0],[15,5,[52,[51,[28,[37,2],[[30,0,[\"chartType\"]],\"map\"],null]],\"display: none;\"]],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@data\",\"@regionClick\",\"@color\"],[[30,0,[\"asGoogleChartData\"]],[28,[37,5],[[30,0],[28,[37,6],[[30,0,[\"regionClick\"]]],null]],null],[30,0,[\"session\",\"currentUser\",\"group\",\"customisation\",\"accentColor\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,5,[52,[28,[37,2],[[30,0,[\"chartType\"]],\"map\"],null],\"display: none;\"]],[12],[1,\"\\n    \"],[8,[39,7],null,[[\"@data\",\"@color\",\"@axis\",\"@bar\",\"@donut\",\"@tooltip\",\"@legend\",\"@padding\",\"@size\",\"@c3chart\"],[[30,0,[\"chartOptions\",\"data\"]],[30,0,[\"chartOptions\",\"color\"]],[30,0,[\"chartOptions\",\"axis\"]],[30,0,[\"chartOptions\",\"bar\"]],[30,0,[\"chartOptions\",\"donut\"]],[30,0,[\"chartOptions\",\"tooltip\"]],[30,0,[\"chartOptions\",\"legend\"]],[30,0,[\"chartOptions\",\"padding\"]],[30,0,[\"chartOptions\",\"size\"]],[30,0,[\"chart\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"skeletons/chart-skeleton\",\"eq\",\"unless\",\"i-map\",\"action\",\"optional\",\"c3-chart\"]]",
    "moduleName": "idol/templates/components/analytics/analytics-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});