define("idol/mixins/commentable", ["exports", "@ember-data/model", "ember-custom-actions", "lodash/array"], function (_exports, _model, _emberCustomActions, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    comments: (0, _model.hasMany)('comment')
  });

  _exports.default = _default;
});