define("idol/templates/i/analytics/trends/rankings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQNbp9I6",
    "block": "[[[10,0],[14,0,\"inner-sub-navigation-sticky\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@top\"],[160]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"inner-sub-navigation \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"nav-pills\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"inner-sub-navigation-content nav-pills-inner\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[\"products tracks artists retails videos productGenres labels companies\"],null]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[1,\"\\n              \"],[8,[39,5],[[24,0,\"nav-button\"]],[[\"@route\",\"@model\"],[\"i.analytics.trends.rankings.ranking\",[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,6],[[28,[37,7],[\"analytics.rankings.subNav.\",[28,[37,8],[[30,2]],null]],null]],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[\"se\",\"key\"],false,[\"sticky-element\",\"if\",\"each\",\"-track-array\",\"w\",\"link-to\",\"t\",\"concat\",\"camelize\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/analytics/trends/rankings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});