define("idol/templates/i", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+M19zPuk",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[\"isMobile\"],null],[28,[37,2],[\"isTablet\"],null]],null],[[[1,\"  \"],[1,[28,[35,3],[\"labelcamp-mobile\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@isSearching\"],[[30,0,[\"isSearching\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@model\",\"@end-search\",\"@isSearching\"],[[30,0,[\"model\"]],\"didEndSearch\",[30,0,[\"isSearching\"]]]],null],[1,\"\\n\"],[8,[39,9],null,null,null]],[],false,[\"if\",\"or\",\"media\",\"set-body-class\",\"i-header\",\"i-sidebar\",\"component\",\"-outlet\",\"i-search\",\"i-clone\"]]",
    "moduleName": "idol/templates/i.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});