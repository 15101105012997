define("idol/templates/components/i-table-with-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4deODLFZ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"body\"],[[50,\"table-with-actions/table-header\",0,null,[[\"model\",\"selectedItems\",\"showActions\",\"actionsList\",\"columns\",\"translationPath\",\"onSelect\",\"toggleAll\",\"disabled\"],[[30,0,[\"model\"]],[30,0,[\"selectedItems\"]],[30,0,[\"showActions\"]],[30,0,[\"actionsList\"]],[30,0,[\"columns\"]],[30,0,[\"translationPath\"]],[30,0,[\"onSelect\"]],[28,[37,3],[[30,0],\"toggleAll\"],null],[30,0,[\"disabled\"]]]]],[50,\"table-with-actions/table-body\",0,null,[[\"onSelect\"],[[30,0,[\"onSelect\"]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "idol/templates/components/i-table-with-actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});