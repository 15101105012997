define("idol/templates/components/i-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ycPw8ckP",
    "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@containerClassNames\",\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@animatable\"],[[28,[37,2],[\" \",[28,[37,3],[\"modal-dialog\",[30,0,[\"modalClass\"]]],null]],null],[28,[37,4],[[30,0],\"closeModal\"],null],\"center\",true,true,true]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"modal-dialog-inner\"],[12],[1,\"\\n      \"],[18,1,[[28,[37,6],null,[[\"header\",\"body\",\"footer\",\"closeModal\"],[[50,\"modal/modal-header\",0,null,[[\"closeModalAction\"],[[28,[37,4],[[30,0],\"closeModal\"],null]]]],[50,\"modal/modal-body\",0,null,null],[50,\"modal/modal-footer\",0,null,null],[28,[37,4],[[30,0],\"closeModal\"],null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"modal-dialog\",\"join\",\"array\",\"action\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "idol/templates/components/i-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});