define("idol/templates/components/i-submission-selected-priority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMTyXhDV",
    "block": "[[[10,0],[15,0,[29,[\"select-submission-priority select-submission-priority-\",[30,0,[\"model\",\"priority\",\"code\"]],\" align-center\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"flag\"],null]],[1,\"\\n  \"],[1,[30,0,[\"model\",\"priority\",\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"svg-jar\"]]",
    "moduleName": "idol/templates/components/i-submission-selected-priority.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});