define("idol/templates/i/distribution/deliveries/send-tasks/send-task-factories/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "43Cerzgp",
    "block": "[[[8,[39,0],null,[[\"@top\"],[60]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,1],[\"filters filters-inline \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]],null]],[12],[1,\"\\n    \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"datepicker filter\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@start\",\"@end\",\"@options\"],[[30,0,[\"selectedStart\"]],[30,0,[\"selectedEnd\"]],[30,0,[\"dateOptions\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],[[24,0,\"dropdown-checkbox filter\"]],[[\"@prepend\",\"@selected\",\"@options\",\"@labelPath\",\"@onApply\"],[[28,[37,5],[\"sendTasks.filters.types\"],null],[30,0,[\"selectedTypes\"]],[30,0,[\"types\"]],\"name\",[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"selectedTypes\"]]],null]],null]]],null],[1,\"\\n      \"],[8,[39,8],[[24,0,\"filter filter-input\"]],[[\"@triggerClass\",\"@tagName\",\"@placeholder\",\"@selected\",\"@onChange\",\"@search\",\"@searchMessage\",\"@searchEnabled\",\"@renderInPlace\",\"@optionsComponent\"],[\"releases-filter-tag\",\"div\",[28,[37,5],[\"sendTasks.filters.tags\"],null],[30,0,[\"selectedResources\"]],[28,[37,6],[[30,0],\"changeSelectedResources\"],null],[28,[37,6],[[30,0],\"searchResources\"],null],[28,[37,5],[\"searchMessage\"],null],true,true,[50,\"power-select-resource-options\",0,null,[[\"tooltipSide\"],[\"top\"]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[\"se\",\"resource\"],false,[\"sticky-element\",\"concat\",\"if\",\"i-daterangepicker\",\"multiselect-checkboxes-dropdown\",\"t\",\"action\",\"mut\",\"power-select-multiple\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/distribution/deliveries/send-tasks/send-task-factories/collection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});