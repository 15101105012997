define("idol/templates/i/catalog/videos/video/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NQZvFHzG",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n\"],[41,[28,[37,1],[\"manage trend\"],null],[[[1,\"    \"],[10,0],[14,0,\"section with-borders\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"justify-between\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[28,[35,2],[\"analytics.trends.title\"],null]],[13],[1,\"\\n        \"],[8,[39,3],null,[[\"@route\",\"@query\"],[\"i.analytics.trends\",[28,[37,4],null,[[\"videoIds\"],[[30,0,[\"model\",\"id\"]]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"seeMore\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,5],null,[[\"@type\",\"@fragment\",\"@filter\"],[\"trend\",\"retails\",[28,[37,4],null,[[\"videoIds\"],[[33,6,[\"id\"]]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n        \"],[8,[30,1,[\"chart\"]],null,null,null],[1,\"\\n      \"]],[1,2,3]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"analytic\",\"fragmentModel\",\"model\"],false,[\"if\",\"can\",\"t\",\"link-to\",\"hash\",\"i-analytics\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/videos/video/analytics.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});