define("idol/templates/i/distribution/deliveries/send-task-factory/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F1c76tgY",
    "block": "[[[10,0],[14,0,\"table st-table\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"table-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"table-column table-column-double\"],[12],[1,[28,[35,0],[\"sendTasks.table.album\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"table-column\"],[12],[1,[28,[35,0],[\"sendTasks.table.platform\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"table-column\"],[12],[1,[28,[35,0],[\"sendTasks.table.deliverable\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"table-column\"],[12],[1,[28,[35,0],[\"sendTasks.table.status\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"table-column table-column-dropdown-actions\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"table-body\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[\"isDeleted\",false,[30,0,[\"model\"]]],null]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@model\",\"@class\",\"@didToggleDeliverable\",\"@onReset\",\"@onDelete\",\"@onSendTaskRender\"],[[30,1],\"st-table-row\",[28,[37,5],[[30,0],\"didToggleDeliverable\"],null],[28,[37,5],[[30,0],\"reset\"],null],[28,[37,5],[[30,0],\"delete\"],null],[28,[37,5],[[30,0],\"didRenderSendTask\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@infinityModel\",\"@classNames\"],[[30,0,[\"model\"]],\"justify-center column\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,7],[[24,0,\"loader-red\"]],[[\"@dotsCount\"],[3]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"]],[\"sendTask\"],false,[\"t\",\"each\",\"-track-array\",\"filter-by\",\"i-send-tasks-item\",\"action\",\"infinity-loader\",\"i-loader\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/distribution/deliveries/send-task-factory/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});