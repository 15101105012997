define("idol/templates/components/i-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3UZKzpF",
    "block": "[[[10,0],[14,0,\"fixed-filler\"],[15,5,[28,[37,0],[[28,[37,1],[\"height: \",[30,0,[\"height\"]],\"px; width: 100%;\"],null]],null]],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"fixed-container\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"html-safe\",\"concat\",\"yield\"]]",
    "moduleName": "idol/templates/components/i-fixed.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});