define("idol/templates/components/i-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oppxJQ+B",
    "block": "[[[41,[51,[30,0,[\"image\"]]],[[[1,\"  \"],[10,1],[14,0,\"avatar-name\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1]],[[\"start\",\"end\"],[0,1]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@name\"],false,[\"unless\",\"substring\"]]",
    "moduleName": "idol/templates/components/i-avatar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});