define("idol/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('fade'), this.use('fade')); // Modal

    this.transition(this.hasClass('liquid-dialog-container'), this.use('fade', {
      duration: 300
    })); // Subnav

    this.transition(this.hasClass('subnav-slide-down'), this.use('explode', {
      use: ['fade', {
        duration: 300
      }]
    }), this.reverse('explode', {
      use: ['fade', {
        duration: 300
      }]
    }));
  }
});