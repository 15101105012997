define("idol/templates/components/i-power-select-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6lOtMDR",
    "block": "[[[8,[39,0],null,[[\"@triggerClass\",\"@disabled\",\"@searchEnabled\",\"@options\",\"@selected\",\"@onChange\",\"@renderInPlace\"],[[29,[\"select-status-trigger \",[30,0,[\"model\",\"status\"]]]],[52,[28,[37,2],[[30,0,[\"session\",\"currentUser\",\"isDistributor\"]]],null],true],false,[30,0,[\"statuses\"]],[30,0,[\"model\",\"status\"]],[28,[37,3],[[30,0],\"changeStatus\",[30,0,[\"model\"]]],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],[[28,[37,5],[\"products.statuses.\",[28,[37,6],[[30,1]],null]],null]],null]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"status\"],false,[\"power-select\",\"if\",\"not\",\"action\",\"t\",\"concat\",\"camelize\"]]",
    "moduleName": "idol/templates/components/i-power-select-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});