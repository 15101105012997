define("idol/templates/i/catalog/assets/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhfIQ1NS",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"assets.title\"],null]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"content-header no-padding-bottom videos-assets-header\"],[12],[1,\"\\n  \"],[8,[39,2],[[24,0,\"back-to\"]],[[\"@route\"],[\"i.catalog.videos\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"arrow-left\"],null]],[1,\" \"],[1,[28,[35,1],[\"assets.back\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"assets.title\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,4],null,[[\"@top\"],[60]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,5],[\"filters filter-tag \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]],null]],[12],[1,\"\\n    \"],[10,0],[14,0,\"inner-sub-navigation-search\"],[12],[1,\"\\n      \"],[8,[39,7],[[24,0,\"filter filter-tag\"],[24,\"triggerClass\",\"videos-filter-tag\"]],[[\"@tagName\",\"@placeholder\",\"@selected\",\"@onChange\",\"@search\",\"@searchMessage\",\"@searchEnabled\",\"@renderInPlace\"],[\"div\",[28,[37,1],[\"assets.filters.tag\"],null],[30,0,[\"selectedResources\"]],[28,[37,8],[[30,0],\"changeSelectedResources\"],null],[28,[37,8],[[30,0],\"searchResources\"],null],[28,[37,1],[\"searchMessage\"],null],true,true]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"videoName\"]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[\"se\",\"resource\"],false,[\"page-title\",\"t\",\"link-to\",\"svg-jar\",\"sticky-element\",\"concat\",\"if\",\"power-select-multiple\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/assets/collection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});