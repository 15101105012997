define("idol/templates/components/i-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UPzRUlbn",
    "block": "[[[10,0],[14,0,\"empty flex-column center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"empty-illustration display-flex center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-column center\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[30,0,[\"image\"]]],[[\"class\"],[\"empty-illustration-shape\"]]]],[1,\"\\n      \"],[10,0],[14,0,\"empty-illustration-shadow\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"empty-title\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"empty-message\"],[12],[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,0],[14,0,\"empty-extra\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"svg-jar\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "idol/templates/components/i-empty.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});