define("idol/templates/password/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2f8u08Jx",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"password.new.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],[[24,0,\"form form-signin center flex-column\"]],[[\"@model\",\"@on-submit\"],[[30,0],[28,[37,3],[[30,0,[\"new\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"logo align-center\"],[12],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-inner form-signin-inner\"],[12],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@type\",\"@label\",\"@name\",\"@placeholder\"],[\"password\",false,\"password\",[28,[37,1],[\"password.new.placeholder\"],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,[[\"@type\",\"@label\",\"@name\",\"@placeholder\"],[\"password\",false,\"passwordConfirmation\",[28,[37,1],[\"password.new.placeholderConfirmation\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[10,0],[14,0,\"form-error\"],[12],[1,\"\\n        \"],[1,[30,0,[\"error\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n      \"],[8,[30,1,[\"submit\"]],[[16,\"disabled\",[30,1,[\"loading\"]]]],[[\"@class\",\"@label\"],[\"lc-btn lc-btn-primary signin\",[52,[30,1,[\"loading\"]],[28,[37,1],[\"resetting\"],null],[28,[37,1],[\"password.form.reset\"],null]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[\"f\"],false,[\"page-title\",\"t\",\"validated-form\",\"perform\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/password/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});