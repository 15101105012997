define("idol/templates/components/i-fixed-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uGy16pwu",
    "block": "[[[10,0],[15,0,[52,[30,0,[\"isReduce\"]],\"fixed-element-header justify-between align-center is-reduce\",\"fixed-element-header justify-between align-center\"]],[12],[1,\"\\n  \"],[10,2],[14,0,\"fixed-element-header-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"fixed-element-header-actions display-flex\"],[12],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"fixed-element-header-action display-flex\"],[4,[38,1],[[30,0],[30,0,[\"reduceFixedElement\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"isReduce\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"icon-reduce\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"icon-plus\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"fixed-element-header-action display-flex\"],[4,[38,1],[[30,0],[30,0,[\"enlargeFixedElement\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"isFullScreen\"]]],null],[[[1,\"        \"],[1,[28,[35,3],[\"maximize\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],[\"overflow-hidden\"],null]],[1,\"\\n        \"],[1,[28,[35,3],[\"minimize\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"fixed-element-header-action display-flex\"],[4,[38,1],[[30,0],[30,0,[\"close\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"cross-big-bold\"],[[\"class\"],[\"fixed-element-close\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[52,[30,0,[\"isReduce\"]],\"hide\"],\" fixed-element-body\"]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"action\",\"not\",\"svg-jar\",\"set-body-class\",\"yield\"]]",
    "moduleName": "idol/templates/components/i-fixed-element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});