define("idol/templates/i/catalog/videos/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkf4Jjgh",
    "block": "[[[10,\"h1\"],[12],[1,\"Video\"],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@route\"],[\"i.catalog.videos.new\"]],[[\"default\"],[[[[1,\"Créer une vidéo\"]],[]]]]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/catalog/videos/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});