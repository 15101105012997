define("idol/components/multiple-filters/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @onSelectAll}}
    <button type="button" class="filter-tags-select-all lc-btn-link lc-btn-link-primary" {{on "click" (queue (fn (optional @onSelectAll) @group) @select.actions.close)}}>{{t "selectAll"}} ({{@group.options.length}})</button>
  {{/if}}
  
  {{yield}}
  */
  {
    "id": "t1Jw8iSG",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"button\"],[24,0,\"filter-tags-select-all lc-btn-link lc-btn-link-primary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[28,[37,3],[[28,[37,4],[[30,1]],null],[30,2]],null],[30,3,[\"actions\",\"close\"]]],null]],null],[12],[1,[28,[35,5],[\"selectAll\"],null]],[1,\" (\"],[1,[30,2,[\"options\",\"length\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,4,null]],[\"@onSelectAll\",\"@group\",\"@select\",\"&default\"],false,[\"if\",\"on\",\"queue\",\"fn\",\"optional\",\"t\",\"yield\"]]",
    "moduleName": "idol/components/multiple-filters/group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});