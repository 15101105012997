define("idol/templates/i/analytics/trends/rankings/ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zm2TeTie",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[10,\"section\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@type\",\"@fragment\",\"@additionalFragment\",\"@exportEnabled\"],[\"trend\",[36,1],\"users\",true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n      \"],[8,[30,1,[\"chart\"]],null,null,null],[1,\"\\n      \"],[8,[30,1,[\"body\"]],null,[[\"@tableClass\",\"@fillMode\",\"@fillColumnIndex\",\"@tableType\",\"@onItemClick\"],[\"ember-table-analytics-ranking\",\"nth-column\",1,\"ranking\",[28,[37,2],[[30,0],\"addAsFilter\"],null]]],null],[1,\"\\n    \"]],[1,2,3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"analytic\",\"fragmentModel\",\"model\"],false,[\"i-analytics\",\"model\",\"action\"]]",
    "moduleName": "idol/templates/i/analytics/trends/rankings/ranking.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});