define("idol/services/feature-control-storage", ["exports", "ember-feature-controls/services/feature-control-storage"], function (_exports, _featureControlStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _featureControlStorage.default;
    }
  });
});