define("idol/components/types/google-cal", ["exports", "ember-add-calendar-button/components/types/google-cal"], function (_exports, _googleCal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleCal.default;
    }
  });
});