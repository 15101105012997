define("idol/templates/i/ui-kit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HLPBKWDd",
    "block": "[[[1,[30,0,[\"i-flex-table\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "idol/templates/i/ui-kit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});