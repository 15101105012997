define("idol/initializers/usable-function-manager", ["exports", "ember-functions-as-helper-polyfill/initializers/usable-function-manager"], function (_exports, _usableFunctionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _usableFunctionManager.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _usableFunctionManager.initialize;
    }
  });
});