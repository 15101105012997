define("idol/instance-initializers/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "session-injection",
    after: 'ember-simple-auth',
    initialize: function initialize(appInstance) {
      appInstance.inject('route', 'session', 'service:session');
      appInstance.inject('controller', 'session', 'service:session');
      appInstance.inject('component', 'session', 'service:session');
    }
  };
  _exports.default = _default;
});