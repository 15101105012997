define("idol/components/validated-inputs/validated-power-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="formfield {{if @isValid "is-valid"}} {{if @isInvalid "is-invalid"}}">
    <@labelComponent />
  
    {{#if @multiple}}
    {{else}}
      <PowerSelect @options={{@options}} @selected={{or @value.content @value}} @onChange={{@update}} @onBlur={{@setDirty}} @renderInPlace={{or @renderInPlace true}} @searchEnabled={{or @searchEnabled true}} @searchField={{"name"}} @allowClear={{@allowClear}} @verticalPosition={{or @verticalPosition "auto"}} as |item|>
        {{or (get item @optionValuePath) item.name item.id}}
      </PowerSelect>
    {{/if}}
  
    <@hintComponent />
    <@errorComponent class="d-block" />
  </div>
  */
  {
    "id": "Q35m8gla",
    "block": "[[[10,0],[15,0,[29,[\"formfield \",[52,[30,1],\"is-valid\"],\" \",[52,[30,2],\"is-invalid\"]]]],[12],[1,\"\\n  \"],[8,[30,3],null,null,null],[1,\"\\n\\n\"],[41,[30,4],[[],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@options\",\"@selected\",\"@onChange\",\"@onBlur\",\"@renderInPlace\",\"@searchEnabled\",\"@searchField\",\"@allowClear\",\"@verticalPosition\"],[[30,5],[28,[37,2],[[30,6,[\"content\"]],[30,6]],null],[30,7],[30,8],[28,[37,2],[[30,9],true],null],[28,[37,2],[[30,10],true],null],\"name\",[30,11],[28,[37,2],[[30,12],\"auto\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,3],[[30,13],[30,14]],null],[30,13,[\"name\"]],[30,13,[\"id\"]]],null]],[1,\"\\n    \"]],[13]]]]],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[30,15],null,null,null],[1,\"\\n  \"],[8,[30,16],[[24,0,\"d-block\"]],null,null],[1,\"\\n\"],[13]],[\"@isValid\",\"@isInvalid\",\"@labelComponent\",\"@multiple\",\"@options\",\"@value\",\"@update\",\"@setDirty\",\"@renderInPlace\",\"@searchEnabled\",\"@allowClear\",\"@verticalPosition\",\"item\",\"@optionValuePath\",\"@hintComponent\",\"@errorComponent\"],false,[\"if\",\"power-select\",\"or\",\"get\"]]",
    "moduleName": "idol/components/validated-inputs/validated-power-select.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});