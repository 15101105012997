define("idol/templates/components/i-power-select-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+eDVmH7o",
    "block": "[[[8,[39,0],null,[[\"@registerAPI\",\"@initiallyOpened\",\"@placeholder\",\"@selected\",\"@onChange\",\"@search\",\"@searchMessage\",\"@searchEnabled\",\"@searchField\",\"@renderInPlace\",\"@allowClear\",\"@triggerComponent\",\"@optionsComponent\"],[[28,[37,1],[[30,0],[30,0,[\"registerSelectAPI\"]]],null],true,[28,[37,2],[\"search.placeholder\"],null],[30,0,[\"filters\"]],[28,[37,1],[[30,0],\"changeSelectedResources\"],null],[28,[37,1],[[30,0],\"searchResources\"],null],[28,[37,2],[\"searchMessage\"],null],true,\"name\",true,false,[50,\"i-power-select-search-trigger\",0,null,[[\"class\",\"previousQuery\"],[\"albums-filter-tag\",[30,0,[\"previousQuery\"]]]]],[50,\"power-select-resource-options\",0,null,[[\"tooltipSide\"],[\"right\"]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"type\"]],[[[1,\"      \"],[10,1],[14,0,\"search-filter-text\"],[12],[1,[30,1,[\"type\"]]],[1,\": \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"search-text\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]]]],[1]]]]],[1,\"\\n\"],[10,0],[14,0,\"input-resize-handler\"],[12],[13]],[\"resource\"],false,[\"power-select-multiple\",\"action\",\"t\",\"component\",\"if\"]]",
    "moduleName": "idol/templates/components/i-power-select-search.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});