define("idol/templates/i/roster/labels/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Wgd3IsJ",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"products\"]],[[\"@items\",\"@titlePath\",\"@subtitlePath\",\"@imagePath\",\"@empty\",\"@duplicate\",\"@delete\"],[[30,0,[\"model\"]],\"name\",\"artist\",\"fullFrontCover\",[28,[37,1],[\"labels.products.empty\"],null],[28,[37,2],[[30,0],\"duplicate\"],null],[28,[37,2],[[30,0],\"delete\"],null]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@infinityModel\",\"@classNames\"],[[30,0,[\"model\"]],\"justify-center column\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"loader-red\"]],[[\"@dotsCount\"],[3]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"i-grid-collection\",\"t\",\"action\",\"infinity-loader\",\"i-loader\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/roster/labels/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});