define("idol/templates/components/i-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cgg2PR83",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,[[28,[37,3],null,[[\"toggleConfirm\"],[[28,[37,4],[\"visible\",[30,0]],null]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[24,0,\"lc-btn lc-btn-secondary\"],[16,\"disabled\",[29,[[52,[30,0,[\"disabled\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,5],[[30,0],[28,[37,6],[\"visible\",[30,0]],null]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"button\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[8,[39,7],[[24,\"modalClass\",\"modal-confirm\"]],[[\"@visible\",\"@didCloseModal\"],[[30,0,[\"visible\"]],[28,[37,5],[[30,0],\"handleAction\",\"cancel\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[30,0,[\"title\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],[[24,0,\"flex-column\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n\"],[41,[30,0,[\"submessage\"]],[[[1,\"      \"],[10,0],[14,0,\"modal-confirm-submessage\"],[12],[1,[30,0,[\"submessage\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"modal-actions flex center\"],[12],[1,\"\\n      \"],[11,3],[24,6,\"#\"],[24,0,\"lc-btn lc-btn-secondary modal-cancel\"],[4,[38,5],[[30,0],\"handleAction\",\"cancel\"],null],[12],[1,[28,[35,8],[\"cancel\"],null]],[13],[1,\"\\n      \"],[11,3],[24,6,\"#\"],[24,0,\"lc-btn lc-btn-primary modal-confirm\"],[4,[38,5],[[30,0],\"handleAction\",\"confirm\"],null],[12],[1,[28,[35,8],[\"yes\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"m\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"toggle-action\",\"action\",\"toggle\",\"i-modal\",\"t\"]]",
    "moduleName": "idol/templates/components/i-confirm.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});