define("idol/utils/currency", ["exports", "money"], function (_exports, _money) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = currency;

  function currency(value) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return _money.default.convert(value, options);
  }
});