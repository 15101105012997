define("idol/templates/i/roster/artists/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YrIoh8Ir",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"artists.title\"],null]],[[\"replace\"],[true]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"content-header no-padding-bottom\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"artists.title\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@top\"],[60]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,3],[\"filters filter-tag \",[52,[30,1,[\"isSticky\"]],\"is-sticky\"]],null]],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"filter\"]],[[\"@tagName\",\"@triggerClass\",\"@placeholder\",\"@selected\",\"@onChange\",\"@search\",\"@searchMessage\",\"@searchEnabled\",\"@renderInPlace\",\"@optionsComponent\"],[\"div\",\"artists-filter-tag\",[28,[37,1],[\"artists.filters.tag\"],null],[30,0,[\"selectedResources\"]],[28,[37,6],[[30,0],\"changeSelectedResources\"],null],[28,[37,6],[[30,0],\"searchResources\"],null],[28,[37,1],[\"searchMessage\"],null],true,true,[50,\"power-select-resource-options\",0,null,[[\"tooltipSide\"],[\"top\"]]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"]],[\"se\",\"resource\"],false,[\"page-title\",\"t\",\"sticky-element\",\"concat\",\"if\",\"power-select-multiple\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/roster/artists/collection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});