define("idol/mixins/resource-searchable-with-exclusions", ["exports", "ember-inflector", "idol/utils/group-by", "idol/mixins/resource-searchable"], function (_exports, _emberInflector, _groupBy, _resourceSearchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create(_resourceSearchable.default, {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var queryParamExcludedAttributes = this.excludedResourceQueryParamAttributes();
      this.initSelectedResourcesProperties(queryParamExcludedAttributes, 'excludedResources', true);
      this.get('resources').forEach(function (resource) {
        var queryExcludedParamKey = _this.excludedResourceQueryParamAttributeFor(resource);

        _this.initResourceProperties(queryExcludedParamKey, resource, 'excluded');
      });
    },
    resetExcludedResourcesObserver: Ember.observer('isExclusionSelectOpen', function () {
      if (!this.get('isExclusionSelectOpen')) {
        this.resetExcludedResources();
      }
    }),
    addExcludedResources: function addExcludedResources(resources) {
      var _this2 = this;

      var excludedResources = this.get('excludedResources') || [];
      resources = resources.concat(excludedResources);
      var groupedResource = (0, _groupBy.default)(resources, function (resource) {
        return (0, _emberInflector.pluralize)(resource.get('modelName'));
      });
      this.get('resources').forEach(function (key) {
        _this2.set("excluded".concat(key), groupedResource[Ember.String.dasherize(key)] || []);
      });
      this.set('excludedResources', resources);
    },
    actions: {
      changeExcludedResources: function changeExcludedResources(resources) {
        var _this3 = this;

        var groupedResource = (0, _groupBy.default)(resources, function (resource) {
          return (0, _emberInflector.pluralize)(resource.get('modelName'));
        });
        this.get('resources').forEach(function (key) {
          _this3.set("excluded".concat(key), groupedResource[Ember.String.dasherize(key)] || []);
        });
        this.set('excludedResources', resources);
      }
    },
    initSelectedResourcesProperties: function initSelectedResourcesProperties(queryParamAttributes, selectedResourcesAttribute, excluded) {
      var _Ember, _Ember2;

      Ember.defineProperty(this, selectedResourcesAttribute, (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(queryParamAttributes).concat([{
        get: function get(key) {
          var _this4 = this;

          if (excluded) {
            var ids = this.excludedResourceQueryParamValues();

            if (!this.hasExcludedResources() && this.hasExcludedResourceQueryParams()) {
              var result = [];
              Object.keys(ids).forEach(function (resource) {
                var resourceIds = ids[resource];

                if (!Ember.isEmpty(resourceIds)) {
                  _this4.store.all(resource.replace('excluded', '').replace('Ids', ''), {
                    filter: {
                      ids: resourceIds
                    },
                    page: {
                      size: 200
                    }
                  }).then(function (resources) {
                    result.addObjects(resources);
                  });
                }
              });
              this.set("_".concat(selectedResourcesAttribute), result);
            }
          } else {
            var _ids = this.resourceQueryParamValues();

            if (!this.hasSelectedResources() && this.hasResourceQueryParams()) {
              var _result = [];
              Object.keys(_ids).forEach(function (resource) {
                var resourceIds = _ids[resource];

                if (!Ember.isEmpty(resourceIds)) {
                  _this4.store.all(resource.replace('Ids', ''), {
                    filter: {
                      ids: resourceIds
                    },
                    page: {
                      size: 200
                    }
                  }).then(function (resources) {
                    _result.addObjects(resources);
                  });
                }
              });
              this.set("_".concat(selectedResourcesAttribute), _result);
            }
          }

          return this.get("_".concat(selectedResourcesAttribute));
        },
        set: function set(key, value) {
          this.set("_".concat(selectedResourcesAttribute), value);
          return value;
        }
      }])));
      Ember.defineProperty(this, 'isExclusionSelectOpen', (_Ember2 = Ember).computed.apply(_Ember2, _toConsumableArray(queryParamAttributes).concat([function () {
        return this.hasExcludedResourceQueryParams();
      }])));
    },
    hasExcludedResources: function hasExcludedResources() {
      return this.get('_excludedResources') !== undefined && !this.get('_excludedResources').length;
    },
    hasExcludedResourceQueryParams: function hasExcludedResourceQueryParams() {
      var ids = this.excludedResourceQueryParamValues();
      return !!Object.keys(ids).filter(function (key) {
        return !Ember.isEmpty(ids[key]);
      }).length;
    },
    excludedResourceQueryParamAttributes: function excludedResourceQueryParamAttributes() {
      var _this5 = this;

      return this.get('resources').map(function (resource) {
        return _this5.excludedResourceQueryParamAttributeFor(resource);
      });
    },
    excludedResourceQueryParamValues: function excludedResourceQueryParamValues() {
      return this.getProperties.apply(this, _toConsumableArray(this.excludedResourceQueryParamAttributes()));
    },
    excludedResourceQueryParamAttributeFor: function excludedResourceQueryParamAttributeFor(resource) {
      return "excluded".concat((0, _emberInflector.singularize)(Ember.String.capitalize(resource)), "Ids");
    },
    resetExcludedResources: function resetExcludedResources() {
      var _this6 = this;

      this.get('resources').forEach(function (resource) {
        _this6.set("excluded".concat(resource), undefined);
      });
      this.set('excludedResources', []);
    }
  });

  _exports.default = _default;
});