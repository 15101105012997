define("idol/templates/components/i-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15juomH/",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@options\",\"@regionClick\"],[[30,0,[\"data\"]],[30,0,[\"options\"]],[30,0,[\"regionClick\"]]]],null]],[],false,[\"geo-chart\"]]",
    "moduleName": "idol/templates/components/i-map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});