define("idol/utils/analytics/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RGBAToHex = RGBAToHex;
  _exports.default = colors;
  var colorsList = {};
  colorsList.default = [24, 36, 79];
  colorsList.download = [0, 137, 255];
  colorsList.stream = [0, 188, 140];
  colorsList.other = [169, 131, 191];
  colorsList.ringtone = [255, 221, 115];
  colorsList.onDemandStream = [112, 213, 152];
  colorsList.streaming = [0, 188, 140];
  colorsList.nonInteractiveStream = [255, 200, 97];
  colorsList.copyScan = [235, 135, 134];
  colorsList.fingerprint = [247, 83, 81];
  colorsList.contentProduction = [97, 73, 237];
  colorsList.newRelease = colorsList.nouveaux = colorsList.new = [252, 221, 73];
  colorsList.recent = colorsList['récents'] = [0, 188, 140];
  colorsList.backCatalogue = [64, 123, 221];
  colorsList.search = [0, 137, 255];
  colorsList.recherche = [86, 204, 242];
  colorsList.searchUser = [132, 218, 246];
  colorsList.playlist = [247, 83, 81];
  colorsList.playlistAutomated = [239, 162, 161];
  colorsList.playlistUser = [242, 184, 183];
  colorsList.playlistEditorial = [247, 212, 212];
  colorsList.artistOrAlbum = [0, 188, 140];
  colorsList.artisteOuAlbum = [0, 188, 140];
  colorsList.artistOrAlbumUser = [130, 217, 166];
  colorsList.artistOrAlbumAutomated = [130, 217, 166];
  colorsList.artistOrAlbumEditorial = [130, 217, 166];
  colorsList.library = [97, 73, 237];
  colorsList.bibliothèque = [162, 149, 219];
  colorsList.libraryUser = [177, 165, 227];
  colorsList.radio = [255, 192, 65]; //radio

  colorsList.radioAutomated = [255, 207, 118];
  colorsList.radioEditorial = [255, 218, 150];
  colorsList.radioUser = [255, 229, 181];
  colorsList.itunes = [33, 150, 243];
  colorsList.amazon = [255, 155, 39];
  colorsList.qobuz = [58, 89, 190];
  colorsList.emusic = [254, 67, 85];
  colorsList.beatport = [82, 199, 87];
  colorsList.juno = [96, 125, 139];
  colorsList.traxsource = [207, 216, 220]; //changer

  colorsList.spotify = [62, 224, 75];
  colorsList.youtube = colorsList.youTube = colorsList.youTubeContentId = colorsList.youTubeArtTrack = [255, 65, 62];
  colorsList.deezer = [0, 151, 232];
  colorsList.appleMusic = colorsList.iTunes = colorsList.apple = [30, 25, 39];
  colorsList.napster = [168, 131, 255];
  colorsList.pandora = [97, 73, 237];
  colorsList.googleMusic = [255, 221, 115];
  colorsList.idolDigitalPremium = [226, 62, 81];
  colorsList.shazam = [121, 162, 245];
  colorsList.tikTok = [0, 199, 192]; // colorsList.all = [0, 255, 0];

  colorsList.soundcloud = [0, 210, 255];
  colorsList.twitter = [0, 172, 237];
  colorsList.instagram = [26, 24, 25];

  function RGBAToHex(color) {
    var r = color[0].toString(16);
    var g = color[1].toString(16);
    var b = color[2].toString(16);
    var a = '';

    if (color[3]) {
      var _a = Math.round(_a * 255).toString(16);
    }

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;
    return "#" + r + g + b + a;
  }

  function colors(name) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'rgba';
    var color = colorsList[Ember.String.camelize(name.toLowerCase())];

    if (!color) {
      return null;
    }

    if (type == 'rgba') {
      color = "rgba(".concat(color.join(','), ",1)");
    }

    if (type == 'hex') {
      color = RGBAToHex(color);
    }

    return color;
  }
});