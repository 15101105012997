define("idol/components/power-select-resource-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="ember-power-select-group" aria-disabled={{if @group.disabled "true"}} role="option" ...attributes>
    <div class="display-flex justify-between">
      <span class="ember-power-select-group-name">{{@group.groupName}}</span>
      <button type="button" class="lc-btn-link lc-btn-link-primary margin-right-s" {{on "click" (queue (fn @onSelectAll @group) @select.actions.close)}}>{{t "selectAll"}}</button>
    </div>
    {{yield}}
  </li>
  */
  {
    "id": "SzM9Bxmc",
    "block": "[[[11,\"li\"],[24,0,\"ember-power-select-group\"],[16,\"aria-disabled\",[52,[30,1,[\"disabled\"]],\"true\"]],[24,\"role\",\"option\"],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"display-flex justify-between\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ember-power-select-group-name\"],[12],[1,[30,1,[\"groupName\"]]],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"lc-btn-link lc-btn-link-primary margin-right-s\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[28,[37,3],[[30,3],[30,1]],null],[30,4,[\"actions\",\"close\"]]],null]],null],[12],[1,[28,[35,4],[\"selectAll\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@group\",\"&attrs\",\"@onSelectAll\",\"@select\",\"&default\"],false,[\"if\",\"on\",\"queue\",\"fn\",\"t\",\"yield\"]]",
    "moduleName": "idol/components/power-select-resource-group.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});