define("idol/components/validated-input/types/select", ["exports", "ember-validated-form/components/validated-input/types/select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _select.default;
    }
  });
});