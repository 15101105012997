define("idol/validations/track", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {// productionLine: [
    //   validatePresence(true)
    // ]
  };
  _exports.default = _default;
});