define("idol/components/artist-ids-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (await @availabilities)}}
    <span class="artist-ids-badge" data-test-artist-ids-badge>
      {{svg-jar "check-trusted"}}
  
      <EmberPopover @tooltipClass="artist-ids-badge-popover" @side="bottom" as |popover|>
        <div class="artist-ids-badge-popover-title" data-test-artist-ids-badge-popover-title>
          Artist IDs
        </div>
        <ul class="artist-ids-badge-popover-availabilities">
          {{#each @availabilities as |availability|}}
            <li class="artist-ids-badge-popover-availability align-center" data-test-artist-ids-badge-popover-availability>
              <img class="artist-ids-badge-popover-availability-logo" src={{availability.dsp.imageUrl}} alt="{{availability.dsp.name}}" />
              {{availability.external}}
            </li>
          {{/each}}
        </ul>
      </EmberPopover>
    </span>
  {{/if}}
  */
  {
    "id": "IEy0iV5+",
    "block": "[[[41,[28,[37,1],[[30,1]],null],[[[1,\"  \"],[10,1],[14,0,\"artist-ids-badge\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"check-trusted\"],null]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@tooltipClass\",\"@side\"],[\"artist-ids-badge-popover\",\"bottom\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"artist-ids-badge-popover-title\"],[12],[1,\"\\n        Artist IDs\\n      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"artist-ids-badge-popover-availabilities\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"artist-ids-badge-popover-availability align-center\"],[12],[1,\"\\n            \"],[10,\"img\"],[14,0,\"artist-ids-badge-popover-availability-logo\"],[15,\"src\",[30,3,[\"dsp\",\"imageUrl\"]]],[15,\"alt\",[29,[[30,3,[\"dsp\",\"name\"]]]]],[12],[13],[1,\"\\n            \"],[1,[30,3,[\"external\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@availabilities\",\"popover\",\"availability\"],false,[\"if\",\"await\",\"svg-jar\",\"ember-popover\",\"each\",\"-track-array\"]]",
    "moduleName": "idol/components/artist-ids-badge.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});