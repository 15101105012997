define("idol/initializers/abilities-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('ability', 'session', 'service:session');
  }

  var _default = {
    name: 'abilities-session',
    initialize: initialize
  };
  _exports.default = _default;
});