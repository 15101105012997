define("idol/components/validated-inputs/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="display-flex justify-between">
    <div class="display-flex">
      <label for="{{@inputId}}">
        {{@label}}{{if @required " *"}}
      </label>
  
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "LdJY+YqY",
    "block": "[[[10,0],[14,0,\"display-flex justify-between\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[29,[[30,1]]]],[12],[1,\"\\n      \"],[1,[30,2]],[1,[52,[30,3],\" *\"]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@inputId\",\"@label\",\"@required\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "idol/components/validated-inputs/label.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});