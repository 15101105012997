define("idol/templates/components/i-table-send-task-locks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "71wX+Ofw",
    "block": "[[[10,0],[14,0,\"table-row-inner cursor-pointer\"],[15,\"onclick\",[28,[37,0],[[30,0],[28,[37,1],[\"isExpanded\",[30,0]],null]],null]],[12],[1,\"\\n  \"],[18,3,[[30,0]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"sendTaskLocks\",\"length\"]],[[[6,[39,4],[[30,0,[\"isExpanded\"]]],[[\"class\"],[[28,[37,5],[\"fade table-playlist-details \",[52,[30,0,[\"isExpanded\"]],\"table-playlist-details-opened\"]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"table-header table-send-task-locks-details-header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[28,[37,8],[\"rank\",\"type\",\"object\",\"message\"],null]],null]],null],null,[[[1,\"          \"],[10,0],[15,0,[29,[\"table-column table-column-\",[30,1]]]],[12],[1,\"\\n            \"],[10,1],[12],[1,[28,[35,9],[[28,[37,5],[\"sendTaskLocks.table.\",[30,1]],null]],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"model\",\"sendTaskLocks\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"table-column table-column-rank\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"table-column\"],[12],[1,[28,[35,9],[[28,[37,5],[\"sendTaskLocks.table.types.\",[30,2,[\"kind\"]]],null]],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"table-column\"],[12],[1,[28,[35,9],[[28,[37,5],[\"sendTaskLocks.table.objects.\",[30,2,[\"lock\"]]],null]],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"table-column table-column-message\"],[12],[1,[30,2,[\"message\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null]],[]]]]]],[]],null]],[\"item\",\"lock\",\"&default\"],false,[\"action\",\"toggle\",\"yield\",\"if\",\"liquid-if\",\"concat\",\"each\",\"-track-array\",\"w\",\"t\"]]",
    "moduleName": "idol/templates/components/i-table-send-task-locks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});