define("idol/templates/i/accounting/reports/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VtpHh42I",
    "block": "[[[8,[39,0],null,[[\"@visible\",\"@didCloseModal\"],[true,[28,[37,1],[[30,0],\"didCancel\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@subtitle\"],[[28,[37,2],[\"accounting.reports.salesCompanyResultModal.title\"],null],[28,[37,3],[[30,0,[\"model\",\"company\",\"name\"]],\" - \",[30,0,[\"model\",\"salesPeriod\",\"name\"]]],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],[[24,0,\"modal-overflow\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"toolbar-download-modal row collapse\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\",\"reports\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"medium-4 columns center\"],[12],[1,\"\\n          \"],[11,3],[24,6,\"#\"],[24,0,\"flex-column center\"],[4,[38,1],[[30,0],\"requestReport\",[30,2]],null],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,2,[\"format\"]]],null]],[1,\"\\n            \"],[1,[28,[35,2],[\"format\"],[[\"format\"],[[28,[37,7],[[30,2,[\"format\"]]],null]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"white-space-pre-line\"],[12],[1,\"\\n      \"],[1,[28,[35,8],[[30,0,[\"model\",\"salesPeriod\",\"comments\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"m\",\"report\"],false,[\"i-modal\",\"action\",\"t\",\"concat\",\"each\",\"-track-array\",\"svg-jar\",\"uppercase\",\"html-safe\"]]",
    "moduleName": "idol/templates/i/accounting/reports/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});