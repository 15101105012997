define("idol/utils/md5", ["exports", "spark-md5"], function (_exports, _sparkMd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.md5FromFile = md5FromFile;

  function md5FromFile(file) {
    var bufferSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Math.pow(1024, 2) * 2;
    return new Promise(function (resolve, reject) {
      var fileReader = new FileReader();
      var fileSlicer = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      var hashAlgorithm = new _sparkMd.default();
      var totalParts = Math.ceil(file.size / bufferSize);
      var currentPart = 0;
      var startTime = new Date().getTime();

      fileReader.onload = function (e) {
        currentPart += 1;
        var buffer = e.target.result;
        hashAlgorithm.appendBinary(buffer);

        if (currentPart < totalParts) {
          processNextPart();
          return;
        }

        resolve({
          hashResult: hashAlgorithm.end(),
          duration: new Date().getTime() - startTime
        });
      };

      fileReader.onerror = function (e) {
        reject(e);
      };

      function processNextPart() {
        var start = currentPart * bufferSize;
        var end = Math.min(start + bufferSize, file.size);
        fileReader.readAsBinaryString(fileSlicer.call(file, start, end));
      }

      processNextPart();
    });
  }
});