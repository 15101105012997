define("idol/templates/components/i-commentable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzDhshXW",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"comments-container\"]],[[\"@title\",\"@close\"],[[28,[37,2],[\"catalog.albums.show.commentsTitle\"],[[\"number\"],[[30,0,[\"commentable\",\"comments\",\"length\"]]]]],[28,[37,3],[[30,0],[28,[37,4],[\"isOpen\",[30,0]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"comments\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[28,[37,7],[\"isPinned\",true,[28,[37,8],[\"createdAt:desc\",[30,0,[\"commentable\",\"comments\"]]],null]],null]],null]],null],null,[[[1,\"        \"],[8,[39,9],null,[[\"@class\",\"@model\",\"@mentionables\"],[[29,[\"comment \",[52,[30,1,[\"isPinned\"]],\"comment-pinned\"],\" \",[52,[30,1,[\"isPrivate\"]],\"comment-private\"]]],[30,1],[30,0,[\"mentionables\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[28,[37,7],[\"isPinned\",false,[28,[37,8],[\"createdAt:desc\",[30,0,[\"commentable\",\"comments\"]]],null]],null]],null]],null],null,[[[1,\"        \"],[8,[39,9],null,[[\"@class\",\"@model\",\"@mentionables\"],[[29,[\"comment \",[52,[30,2,[\"isPinned\"]],\"comment-pinned\"],\" \",[52,[30,2,[\"isPrivate\"]],\"comment-private\"]]],[30,2],[30,0,[\"mentionables\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@class\",\"@model\",\"@mentionables\",\"@didSave\",\"@didDelete\"],[\"comment-reply-container\",[30,0,[\"currentComment\"]],[30,0,[\"mentionables\"]],[28,[37,3],[[30,0],\"didCreateComment\"],null],[28,[37,3],[[30,0],\"didDeleteComment\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"comment\",\"comment\"],false,[\"if\",\"i-fixed-element\",\"t\",\"action\",\"toggle\",\"each\",\"-track-array\",\"filter-by\",\"sort-by\",\"i-comment-editor\"]]",
    "moduleName": "idol/templates/components/i-commentable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});