define("idol/templates/components/i-power-select-submission-priority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S+3jUPoM",
    "block": "[[[8,[39,0],null,[[\"@triggerClass\",\"@dropdownClass\",\"@searchEnabled\",\"@options\",\"@selectedItemComponent\",\"@selected\",\"@onChange\",\"@renderInPlace\"],[\"select-submission-trigger\",\"select-submission-dropdown\",false,[30,0,[\"priorities\"]],[50,\"i-submission-selected-priority\",0,null,[[\"model\"],[[30,0,[\"model\"]]]]],[30,0,[\"selectedPriority\"]],[28,[37,2],[[30,0],\"updatePriority\"],null],true]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"select-submission-priority select-submission-priority-\",[30,1,[\"code\"]],\" align-center\"]]],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"flag\"],[[\"class\"],[\"select-submission-priority-dropdown\"]]]],[10,1],[15,0,[52,[28,[37,5],[[30,0,[\"selectedPriority\",\"code\"]],[30,1,[\"code\"]]],null],\"selected\"]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"resource\"],false,[\"power-select\",\"component\",\"action\",\"svg-jar\",\"if\",\"eq\"]]",
    "moduleName": "idol/templates/components/i-power-select-submission-priority.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});