define("idol/components/validated-inputs/hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  <IHint @side="right" @message={{@hint}} @interactive={{true}} />
  
  */
  {
    "id": "vUYHRqtI",
    "block": "[[[18,2,null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@side\",\"@message\",\"@interactive\"],[\"right\",[30,1],true]],null],[1,\"\\n\"]],[\"@hint\",\"&default\"],false,[\"yield\",\"i-hint\"]]",
    "moduleName": "idol/components/validated-inputs/hint.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});