define("idol/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UhPQR5Ps",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"medium-4 columns\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"products.edit\",[30,1]]],[[\"default\"],[[[[1,[30,1,[\"name\"]]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[\"product\"],false,[\"each\",\"-track-array\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});