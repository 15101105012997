define("idol/templates/i/users/groups/show/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3mQR41MI",
    "block": "[[[8,[39,0],null,[[\"@visible\",\"@didCloseModal\"],[true,[28,[37,1],[[30,0],\"didCloseModal\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[28,[37,2],[\"users.groups.edit.title\"],null]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],[[24,0,\"center flex-column\"]],null,[[\"default\"],[[[[1,\"\\n    Test\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[\"m\"],false,[\"i-modal\",\"action\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "idol/templates/i/users/groups/show/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});