define("idol/templates/i/analytics/sales/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/iuPwiRk",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@type\",\"@fragment\",\"@chartType\",\"@exportEnabled\"],[\"sale\",\"territories\",\"map\",true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@subtitle\",\"@number\"],[[28,[37,1],[[28,[37,2],[[30,2,[\"numbers\",\"trackQuantity\"]]],[[\"allowEmpty\"],[true]]],\" \",[28,[37,3],[\"analytics.rankings.column.transactions\"],null]],null],[28,[37,4],[[28,[37,5],[[30,2,[\"numbers\",\"amount\"]]],null]],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"chart\"]],null,[[\"@regionClick\"],[[28,[37,6],[[30,0],\"selectTerritory\"],null]]],null],[1,\"\\n    \"],[8,[30,1,[\"body\"]],null,[[\"@tableClass\",\"@fillMode\",\"@fillColumnIndex\",\"@onItemClick\"],[\"ember-table-analytics-ranking\",\"nth-column\",1,[28,[37,6],[[30,0],\"addAsFilter\"],null]]],null],[1,\"\\n  \"]],[1,2,3]]]]],[1,\"\\n\"],[13]],[\"analytic\",\"fragmentModel\",\"model\"],false,[\"i-analytics\",\"concat\",\"format-number\",\"t\",\"format-money\",\"currency\",\"action\"]]",
    "moduleName": "idol/templates/i/analytics/sales/map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});